<template id="preauthAdd">
<div>
    <b-container class="preauthadd-container">
        <div>
            <b-row>
                <b-col cols="2">
                    <div class="pb-2">
                        <b-button class="avo-danger-btn mb-2" @click="goPreauthDashboard"><b-icon icon="arrow-left-circle" class="mr-2" />Cancel Request</b-button>
                    </div>
                </b-col>
                <b-col class="preauthadd-header pl-2">
                    <h4 class="mb-0 mt-2">{{doCreateVisit ? 'New preauthorization request' : 'Add preauth for selected visit'}}</h4>
                </b-col>
                <br />
            </b-row>
        </div>
            <b-row>
                <b-col cols="2" class="preauthadd-sidebar pr-0 pl-0">
                    <b-nav>
                        <b-nav-item v-if="doCreateVisit" @click="goToSection('1')"><b-avatar text="1" size="1.5rem"></b-avatar> Patient information</b-nav-item>
                        <b-nav-item v-if="doCreateVisit" @click="goToSection('2')"><b-avatar text="2" size="1.5rem"></b-avatar> Patient's insurance</b-nav-item>
                        <b-nav-item v-if="doCreateVisit" @click="goToSection('3')"><b-avatar text="3" size="1.5rem"></b-avatar> Appointment details</b-nav-item>
                        <b-nav-item @click="goToSection('4')"><b-avatar text="4" size="1.5rem"></b-avatar> CPT & ICD codes</b-nav-item>
                        <b-nav-item @click="goToSection('5')"><b-avatar text="5" size="1.5rem"></b-avatar> Authorization information</b-nav-item>
                    </b-nav>
                </b-col>
                <b-col cols="10" class="form-area p-3 pb-5">
                    <b-alert
                        v-for="(alert, index) in alertMessages"
                        :key="index"
                        :show="showAlerts"
                        variant="danger">
                        <b>{{alert}}</b>
                    </b-alert>

                    <b-overlay spinner-type="grow" spinner-small :show="!doCreateVisit">
                    <div class="section-header-text d-flex align-items-center">
                        <b-avatar ref="1" text="1" size="2rem" class="mr-2"></b-avatar> Patient Information
                    </div>
                    <hr>
                    <template #overlay><div></div></template>
                    </b-overlay>
                    
                    <div v-if="doCreateVisit" class="patient-info-section mb-4">
                        <div class="multiselect-label">Search existing patients</div>
                        <patient-selection class="mb-4"/>
                        <patient-display class="mb-4"/>
                        <div class="mb-2" v-if="!patientSelected">Or add a new patient</div>
                        <create-patient style="max-width:1000px" class="mb-4"/>
                        <div v-if="!patientSubmitted && patientSelected" class="">
                            <b-button class="avo-primary-btn" @click="checkSelectedPatientValid">Confirm</b-button>
                        </div>
                    </div>

                    <b-overlay spinner-type="grow" spinner-small :show="!doCreateVisit">
                    <div class="section-header-text d-flex align-items-center">
                        <b-avatar ref="2" text="2" size="2rem" class="mr-2"></b-avatar> Patient's Insurance
                    </div>
                    <!-- <b-alert
                        v-for="(alert, index) in insuranceAlertMessages"
                        :key="index"
                        :show="showInsuranceAlerts"
                        variant="danger">
                        <b>{{alert}}</b>
                    </b-alert> -->
                    <hr>
                    <template #overlay><div></div></template>
                    </b-overlay>
                    
                    <patient-insurance-preauth v-if="doCreateVisit" class="mb-5"/>

                    <b-overlay spinner-type="grow" spinner-small :show="!doCreateVisit">
                    <div class="section-header-text d-flex align-items-center">
                        <b-avatar ref="3" text="3" size="2rem" class="mr-2"></b-avatar> Appointment Details
                    </div>
                    <hr>
                    <template #overlay><div></div></template>
                    </b-overlay>
                    
                    <visit-add v-if="doCreateVisit" style="max-width:1000px" class="mb-5"/>
                    
                    <div class="section-header-text d-flex align-items-center">
                        <b-avatar ref="4" text="4" size="2rem" class="mr-2"></b-avatar> CPT & ICD Codes
                    </div>
                    <hr>
                    
                    <div v-if="patientSubmitted && insurancesSubmitted && visitSubmitted">
                        <preauth-add-codes :practice-id="practiceId" class="mb-5"/>
                    </div>

                    <div class="d-flex align-items-center">
                        <b-avatar ref="5" text="5" size="2rem" class="mr-2"></b-avatar>
                        <div class="d-flex flex-column justify-content-end pt-3">
                            <div class="section-header-text">Authorization Information</div>
                            <i class="avo-text-light">This section is optional</i>
                        </div>
                         
                    </div>
                    <hr>
                    <div v-if="patientSubmitted && insurancesSubmitted && visitSubmitted && codesSaved">
                        <preauth-add-auth-info class="mb-5" />
                    </div>
                    <div v-if="patientSubmitted && insurancesSubmitted && visitSubmitted && codesSaved" class="d-flex mt-5">
                        <b-overlay variant="white" :show="createPreauthLoading">
                            <b-button variant="outline-primary" class="avo-primary-btn" @click="savePreauth()">
                                Create Preauth
                                <b-icon icon="calendar2-check" class="ml-2" />
                            </b-button>
                        </b-overlay>
                    </div>
                    <!-- {{patient}}
                    {{visit}}
                    {{icdCodes}}
                    {{cptCodes}}
                    {{authInfo}} -->
                </b-col>
            </b-row>
    </b-container>
</div>
</template>

<script>
import { make_url, authenticated_request } from '../../common.js'

import PatientSelection from '../patients/PatientSelection.vue'
import PatientInsurancePreauth from '../patients/PatientInsurancePreauth.vue'
import PatientDisplay from '../patients/PatientDisplay.vue'
import CreatePatient from '../patients/CreatePatient.vue'
import VisitAdd from './VisitAdd.vue'
import PreauthAddCodes from './PreauthAddCodes.vue'
import PreauthAddAuthInfo from './PreauthAddAuthInfo.vue'

export default {
    name: 'PreauthAdd',
    template: '#preauthAdd',
    props: ['visitId',],
    components: { PatientSelection,
                PatientDisplay,
                CreatePatient,
                PatientInsurancePreauth,
                VisitAdd,
                PreauthAddCodes,
                PreauthAddAuthInfo
    },
    data() {
		return {
            createPreauthLoading: false
		}
	},
    beforeDestroy(){
        this.$store.commit("preauths/setDefaultPatient");
        this.$store.commit('providerPortal/setBackgroundWhite', false);
    },
    methods: {
        goPreauthDashboard: function() {
            this.$store.commit("preauths/setShowNewPreauth", false);
            this.$store.commit('providerPortal/setBackgroundWhite', false);
            this.$store.commit('preauths/clearSelectedPreauth');
            this.$store.commit("preauths/clearInsurances");
            this.$store.commit("preauths/clearCodes");
            this.$store.commit("preauths/clearVisit");
            this.$store.commit("preauths/clearAuthInfo");
        },
        goToSection(refName) {
            var element = this.$refs[refName];
            element.$el.scrollIntoView({ behavior: 'smooth'});
        },
        checkSelectedPatientValid: function() {
            this.$store.commit("preauths/setTrySubmitPatient", true);
        },
        savePreauth(){
            this.createPreauthLoading = true;
            var data = {
                preauth: {
                    patient_id: this.patient ? this.patient.patient_id : this.selectedPreauth.patient_id,
                    practice_id: this.visit ? this.visit.practice_id : this.selectedPreauth.practice_id,
                    practice_location_id: this.visit? this.visit.practice_location_id : this.selectedPreauth.practice_location_id,
                    state: this.authInfo ? this.authInfo.preauth_state : null,
                    insurance_auth_no: this.authInfo ? this.authInfo.insurance_auth_no : null,
                    reference_no: this.authInfo? this.authInfo.reference_no : null,
                    effective_start_date: this.authInfo ? this.authInfo.effective_start_date : null,
                    effective_end_date: this.authInfo ? this.authInfo.effective_end_date : null,
                    quantity_approved: this.authInfo ? this.authInfo.quantity_approved : null,
                    quantity_remaining: this.authInfo ? this.authInfo.quantity_remaining : null,
                },
                visit: this.visit,
                visit_id: this.visitId,
                icd_codes: this.icdCodes,
                cpt_codes: this.cptCodes
            }
            const url = make_url("/reports/preauth");
            const vm = this;
            const promise = authenticated_request({
                method: "post",
                url: url,
                data: data
            }).then(function(response){
                if (response){
                    console.log(response);
                    vm.$store.commit("preauths/setShowNewPreauthSuccess", true);
                    vm.createPreauthLoading = false;
                    vm.goPreauthDashboard();
                }
            }).catch(function(error){
                console.log(error.response)
                vm.showError(error);
            }).finally(()=>{
                vm.createPreauthLoading = false;
                vm.$store.commit('preauths/setShowManagePreauthQuickView', false);
                vm.$store.commit('preauths/setPreauthsUpdated', true);
            });
            return promise;
        }
    },
    watch: {
    },
    computed: {
        // submittal criteria, last ones optinoal
        patientSubmitted() {
            if (this.doCreateVisit) {
                return this.$store.state.preauths.patient.submitted;
            }
            return true;
        },
        patientSelected() {
            if (this.doCreateVisit) {
                return this.$store.state.preauths.patient.selected;
            }
            return true;
        },
        insurancesSubmitted() {
            if (this.doCreateVisit) {
                return this.$store.state.preauths.insurance.insurancesSaved;
            }
            return true;
        },
        visitSubmitted() {
            if (this.doCreateVisit) {
                return this.$store.state.preauths.visit.visitSaved;
            }
            return true;
        },
        codesSaved(){
            return this.$store.state.preauths.codes.codesSaved;
        },

        // gathering info used for new preauth submittal
        patient() {
            return this.$store.state.preauths.patient.patient;
        },
        practiceId() {
            return this.visit ? this.visit.practice_id : this.selectedPreauth.practice_id;
        },
        visit() {
            return this.$store.state.preauths.visit.visit;
        },
        icdCodes() {
            return this.$store.state.preauths.codes.storedICDs;
        },
        cptCodes() {
            return this.$store.state.preauths.codes.storedCPTs;
        },
        authInfo() {
            return this.$store.state.preauths.authinfo.authInfo;
        },

        // gathering info used for creating a preauth for an already existing visit
        selectedPreauth() {
            return this.$store.state.preauths.preauth.selectedPreauth;
        },

        doCreateVisit() {
            return !this.visitId || this.$store.state.preauths.preauth.selectedPreauth.preauth.preauth_id;
        },

        showAlerts() {
            return this.$store.state.preauths.patient.showAlerts;
        },
        alertMessages() {
            return this.$store.state.preauths.patient.alertMessages;
        },
        showInsuranceAlerts() {
            return this.$store.state.preauths.insurance.showInsuranceAlerts;
        },
        insuranceAlertMessages() {
            return this.$store.state.preauths.insurance.insuranceAlertMessages;
        },
    },
    async mounted(){
        this.$store.commit('providerPortal/setBackgroundWhite', true);
    }
}
</script>

<style scoped>
::v-deep .multiselect {
    width: 400px;
}
.multiselect-label {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    color:var(--dark);
    margin-bottom:8px;
}
.alert {
    background: #FDEEED 0% 0% no-repeat padding-box;
    border: 1px solid var(--red);
    border-radius: 5px;
    text-align: center;
    letter-spacing: 0px;
    color: var(--red);
}
.section-header-text {
  font-weight:500;
  font-size:18px;
  color: var(--dark);
}
.preauthadd-container {
    max-width: 3000px;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
}
.preauthadd-header {
    /* border-bottom: 1px solid #C9D2DF; */
}
.preauthadd-sidebar {
    /* border-right: 1px solid #C9D2DF; */
    height: 100vh;
    font-size: 14px;
    padding-top: 36px;
}
.form-area {
    border-top: 1px solid #C9D2DF;
    border-left: 1px solid #C9D2DF;
    border-top-left-radius: 10px;
    /* background-color: #F8FAFD !important; */
    background-color: #F7F8FA !important;
}
.preauthadd-sidebar a {
    color: var(--blue);
}
.nav {
    display: unset;
}
::v-deep .b-avatar {
    scroll-margin-top: 86px !important;
}
</style>