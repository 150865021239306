<template>
  <b-container>
    <div v-if="showNewPreauth">
      <preauth-add :visitId="selectedPreauthVisitId" />
    </div>
    <div>
      <preauth-schedule></preauth-schedule>
    </div>
  </b-container>
</template>

<script>
import PreauthAdd from "../../components/preauths/PreauthAdd.vue";
import PreauthSchedule from "../../components/preauths/PreauthSchedule.vue";

export default {
  name: "ProviderPreauths",
  components: {
    PreauthSchedule,
    PreauthAdd,
  },
  computed: {
    showNewPreauth() {
      return this.$store.state.preauths.preauth.showNewPreauth;
    },
    selectedPreauthVisitId() {
      return this.$store.state.preauths.preauth?.selectedPreauth?.visit_id;
    },
  },
  beforeMount() {
    this.$store.commit("providerPortal/setActiveNavTab", "preauths");
  },
};
</script>

<style scoped>
@media only screen and (min-width: 800px) {
  .container {
    max-width: 100%;
  }
}
</style>