<template id="visitAdd">
<div v-if="insuranceSubmitted && patientSubmitted" class="appointment-container mb-5">
    <b-row>
        <b-col cols="6">
            <label class="selecter-label">Practice</label>
            <b-input-group>
                <multiselect
                    openDirection="bottom"
                    placeholder="Search by practice name" 
                    :custom-label="practiceLabel"
                    v-model="selectedPractice"
                    track-by="practice_id"
                    :options="selectablePractices"
                    :loading="practicesLoading"
                    :internal-search="false"
                    :show-labels="false"
                    @search-change="debouncePractices"
                    :showNoOptions="true"
                    :allow-empty="false"
                    :class="visitFieldStates('practice_id') == false ? 'invalid': ''">
                    <template slot="noResult">
                        No practices matched your search
                    </template>
                    <template slot="noOptions">
                        Start typing a practice name...
                    </template>
                </multiselect>
            </b-input-group>
        </b-col>
    </b-row>
    <div class="d-flex justify-content-center my-2">
        <div>
            <b-alert
                v-model="showAlert"
                :ariant="alertError?'danger':'success'"
                style="width:750px"
                dismissible>
                <b>{{alertMessage}}</b>
            </b-alert>
        </div>
    </div>
    <b-row>
        <b-col cols="6">
            <label class="selecter-label">Service Facility Location</label>
            <b-input-group>
                <multiselect
                    openDirection="bottom"
                    placeholder="Search by practice name" 
                    :custom-label="locationLabel"
                    v-model="selectedLocation"
                    track-by="location_id"
                    :options="selectableLocations"
                    :loading="locationsLoading"
                    :internal-search="false"
                    :show-labels="false"
                    @search-change="debounceLocations"
                    :showNoOptions="true"
                    :disabled="isNewLocation || visit.practice_id == null"
                    :allow-empty="false"
                    :class="visitFieldStates('practice_location_id') == false ? 'invalid': ''">
                    <template slot="noResult">
                        No locations matched your search
                    </template>
                    <template slot="noOptions">
                        Start typing a location name or address...
                    </template>
                    <template slot="afterList" >
                        <div class="new-practice-option" @click="isNewLocation = true">
                            <div style="padding:12px 12px 0 12px;"><b-icon class="mr-2" icon="plus-circle"></b-icon>New Location</div>
                        </div>
                    </template>
                </multiselect>
            </b-input-group>
        </b-col>
    </b-row>
    <b-row v-if="isNewLocation" class="mt-4">
        <b-col>
            <b-card class="mb-3 pb-3">
                <template #header>
                    <strong>New Location</strong>
                    <div style="display:inline-block;float:right">
                        <b-button @click="clearNewLocation" class="close-btn">
                            <b-icon icon="x" class="mr-2"/>
                        </b-button>
                    </div>
                </template>
                <b-row>
                    <b-col>
                        <label class="selecter-label">Name</label>
                        <b-form-input v-model="newLocation.location.name" :state="locationFieldStates('name')"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <label class="selecter-label">PTAN</label>
                        <b-form-input v-model="newLocation.location.ptan"></b-form-input>
                    </b-col>
                    <b-col cols="4">
                        <label class="selecter-label">Fax</label>
                        <b-form-input v-model="newLocation.address.fax" type="number" ></b-form-input>
                    </b-col>
                    <b-col cols="4">
                        <label class="selecter-label">Phone</label>
                        <b-form-input v-model="newLocation.address.telephone" type="number"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label class="selecter-label">Street address</label>
                        <b-form-input v-model="newLocation.address.address_line1"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <label class="selecter-label">City</label>
                        <b-form-input v-model="newLocation.address.city"></b-form-input>
                    </b-col>
                    <b-col cols="4">
                        <label class="selecter-label">State</label>
                        <b-form-input v-model="newLocation.address.state"></b-form-input>
                    </b-col>
                    <b-col cols="4">
                        <label class="selecter-label">Zip</label>
                        <b-form-input v-model="newLocation.address.zip" type="number"></b-form-input>
                    </b-col>
                </b-row>
                <div class="text-right mt-2">
                    <b-button :disabled="creatingLocation" @click="submitLocation()" class="avo-primary-btn">Create location</b-button>
                </div>
            </b-card>
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="4">
            <label class="selecter-label">POS</label>
            <b-form-select v-model="visit.visit_pos" :disabled="!selectedLocation" :state="visitFieldStates('visit_pos')">
                <template #first>
                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                </template>
                <option
                    v-for="(option, idx) in visitOptionMap.pos_types"
                    :key="idx"
                    :value="idx">
                    {{idx}} - {{ option }}
                </option>
            </b-form-select>
        </b-col>
        <b-col cols="4">
            <label class="selecter-label">Tax ID</label>
            <b-form-input v-if="selectedPractice" v-model="selectedPractice.tax_id" disabled ></b-form-input>
            <b-form-input disabled v-else />
        </b-col>
        <b-col cols="4">
            <label class="selecter-label">NPI</label>
            <b-form-input v-if="selectedPractice" v-model="selectedPractice.group_npi" disabled ></b-form-input>
            <b-form-input disabled v-else />
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="4">
            <label class="selecter-label">Visit Type</label> <!-- Changed from: Procedure type -->
            <b-form-group
                id="visit-procedure_type">
                <b-form-select 
                    v-model="visit.procedure_type_id" 
                    text-field="name"
                    value-field="procedure_type_id"
                    :options="procedure_types" 
                    :disabled="!selectedLocation || procedureTypesMapLoading">
                    <template #first>
                        <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                    </template>
                </b-form-select>
            </b-form-group>
        </b-col>
        <b-col cols="4">
            <label class="selecter-label">Date of service</label>
                <b-form-input type="date" 
                :disabled="!selectedLocation"
                v-model="dateOfService" 
                placeholder="Choose a date"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                :state="visitFieldStates('date_of_service')"></b-form-input>
        </b-col>
        <b-col cols="2" class="pr-1">
            <label class="selecter-label">Start time</label>
                <b-form-input type="time"
                    :disabled="!selectedLocation"
                    v-model="startTime"
                    placeholder="Choose a start time"
                    locale="en"
                    :state="visitFieldStates('start_time')"></b-form-input>
        </b-col>
        <b-col cols="2" class="pl-1">
            <label class="selecter-label">End time</label>
                <b-form-input type="time"
                    :disabled="!selectedLocation"
                    v-model="endTime"
                    placeholder="Choose an end time"
                    locale="en"></b-form-input>
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="4">
            <label class="selecter-label">Servicing provider</label>
            <b-input-group>
                <multiselect
                    openDirection="bottom"
                    placeholder="Search by provider name" 
                    :custom-label="providerLabel"
                    v-model="selectedServicingProvider"
                    track-by="provider_id"
                    :options="selectableProviders"
                    :loading="servicingProvidersLoading"
                    :internal-search="false"
                    :show-labels="false"
                    @search-change="debounceServicingProviders"
                    :showNoOptions="true"
                    :allow-empty="false"
                    :disabled="!selectedLocation"
                    :class="visitFieldStates('servicing_provider_id') == false ? 'invalid': ''">
                    <template slot="noResult">
                        No providers matched your search
                    </template>
                    <template slot="noOptions">
                        Start typing a provider name...
                    </template>
                </multiselect>
            </b-input-group>
            
        </b-col>
        <b-col cols="4">
            <label class="selecter-label">Resource provider</label>
            <b-input-group>
                <multiselect
                    openDirection="bottom"
                    placeholder="Search by provider name" 
                    :custom-label="providerLabel"
                    v-model="selectedResourceProvider"
                    track-by="provider_id"
                    :options="selectableProviders"
                    :loading="resourceProvidersLoading"
                    :internal-search="false"
                    :show-labels="false"
                    @search-change="debounceResourceProviders"
                    :showNoOptions="true"
                    :allow-empty="false"
                    :disabled="!selectedLocation">
                    <template slot="noResult">
                        No providers matched your search
                    </template>
                    <template slot="noOptions">
                        Start typing a provider name...
                    </template>
                </multiselect>
            </b-input-group>
        </b-col>
        <b-col cols="4">
            <label class="selecter-label">Referring/PCP</label>
            <b-input-group>
                <multiselect
                    openDirection="bottom"
                    placeholder="Search by provider name" 
                    :custom-label="referringProviderLabel"
                    v-model="selectedReferringProvider"
                    track-by="provider_id"
                    :options="selectableProviders"
                    :loading="referringProvidersLoading"
                    :internal-search="false"
                    :show-labels="false"
                    @search-change="debounceReferringProviders"
                    :showNoOptions="true"
                    :allow-empty="false"
                    :disabled="!selectedLocation">
                    <template slot="noResult">
                        No providers matched your search
                    </template>
                    <template slot="noOptions">
                        Start typing a provider name...
                    </template>
                </multiselect>
            </b-input-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col class="text-right mt-4">
            <b-button v-if="!visitSaved" class="avo-primary-btn" variant="outline-primary" @click="storeVisit()">Continue</b-button>
        </b-col>
    </b-row>
</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapState } from 'vuex'
import { make_url, authenticated_request, debounce, alertsMixin } from '../../common.js'


export default {
    name: 'VisitAdd',
    template: '#visitAdd',
    components: { 
        Multiselect,
    },
    mixins: [alertsMixin],
    data() {
		return {
            visitOptionMap: {},
            visit: {
                patient_id: null,
                practice_id: null,
                practice_location_id: null,
                servicing_provider_id: null,
                resource_provider_id: null,
                referring_provider_id: null,
                visit_type: null,
                procedure_type_id: null,
                visit_pos: null,
                start_time: null,
                end_time: null,
                date_of_service: null,
            },
            visitValidator: {
                practice_id: null,
                practice_location_id: null,
                servicing_provider_id: null,
                // visit_type: null,
                visit_pos: null,
                // start_time: null,
                // end_time: null,
                date_of_service: null,
            },
            dateOfService: null,
            startTime: null,
            endTime: null,
            
            // practice multiselect stuff
            searchText: '',
            selectedPractice: null,
            practicesLoading: false,
            selectablePractices: [],

            // location multiselect stuff
            locationSearchText: '',
            selectedLocation: null,
            locationsLoading: false,
            selectableLocations: [],

            // new location stuff
            isNewLocation: false,
            creatingLocation: false,
            newLocation: {
                "location": {
                    name: null,
                    ptan: null
                },
                "address": {
                    fax: null,
                    telephone: null,
                    address_line1: null,
                    city: null,
                    state: null,
                    zip: null,
                }
            },
            locationValidator: {
                name: null,
            },

            // provider multiselects stuff
            providerSearchText: '',
            selectableProviders: [],
            procedure_types: [],
            procedureTypesMapLoading: false,
            selectedServicingProvider: null,
            selectedResourceProvider: null,
            selectedReferringProvider: null,
            servicingProvidersLoading: false,
            resourceProvidersLoading: false,
            referringProvidersLoading: false,

            alertError: true,
		}
	},
    methods: {
        setVisitValidator() {
            this.visitValidator = {
                practice_id: !this.visit.practice_id ? "required" : null,
                practice_location_id: !this.visit.practice_location_id ? "required" : null,
                servicing_provider_id: !this.visit.servicing_provider_id ? "required" : null,
                // visit_type: !this.visit.visit_type ? "required" : null,
                visit_pos: !this.visit.visit_pos ? "required" : null,
                // start_time: !this.visit.start_time ? "required" : null,
                date_of_service: !this.visit.date_of_service ? "required" : null,
            }
        },
        clearVisitValidator() {
            this.visitValidator = {
                practice_id: null,
                practice_location_id: null,
                servicing_provider_id: null,
                // visit_type: null,
                visit_pos: null,
                // start_time: null,
                date_of_service: null,
            }
        },
        validateVisit() {
            return Object.values(this.visitValidator).every(o => o === null);
        },
        visitFieldStates(field) {
            return this.visitValidator[field] ? false : null;
        },
        storeVisit() {
            this.setVisitValidator()
            if (this.validateVisit()){
                console.log("saving visit info to store");
                this.visit.rendering_provider_id = this.visit.servicing_provider_id;
                this.$store.commit("preauths/setVisit", this.visit);
            }
        },
        practiceSearchChange: function(searchQuery) {
            this.practicesLoading = true;
            this.searchText = searchQuery
            if (searchQuery.length > 0 ) {
                this.practiceAutocomplete();
            } else {
                this.practicesLoading = false;
            }
        },
        practiceAutocomplete() {
            var vm = this;
            const url = make_url("/reports/practices/autocomplete");
            authenticated_request({
                method: "get",
                url: url,
                params: {
                    search: this.searchText,
                    limit: 10
                }
            }).then(function(response){
                vm.practicesLoading = false;
                const practices = response.data.practices;
                vm.selectablePractices = practices || [];
                return practices || [];
            }).catch(function(errors){
                console.log(errors.response)
                vm.showError(errors);
            });
        },
        practiceLabel(practice){
            if (practice.name){
                return practice.name
            } else {
                return practice.practice_id
            }
        },
        
        locationSearchChange: function(searchQuery) {
            this.locationsLoading = true;
            this.locationSearchText = searchQuery
            if (searchQuery.length > 0 ) {
                this.locationAutocomplete();
            } else {
                this.locationsLoading = false;
            }
            
        },
        locationAutocomplete() {
            var vm = this;
            const url = make_url("/reports/practices/locations/autocomplete");
            authenticated_request({
                method: "get",
                url: url,
                params: {
                    search: this.locationSearchText,
                    practice_id: this.visit.practice_id,
                    limit: 10
                }
            }).then(function(response){
                vm.locationsLoading = false;
                const locations = response.data.locations;
                console.log(locations)
                vm.selectableLocations = locations || [];
                return locations || [];
            }).catch(function(errors){
                vm.showError(errors);
            });
        },
        locationLabel(location){
            if (location.name){
                return location.name
            } else {
                return location.location_id
            }
        },

        setLocationValidator() {
            this.locationValidator = {
                name: !this.newLocation.location.name ? "required" : null,
            }
        },
        clearLocationValidator() {
            this.locationValidator = {
                name: null,
            }
        },
        validateLocation() {
            return Object.values(this.locationValidator).every(o => o === null);
        },
        locationFieldStates(field) {
            return this.locationValidator[field] ? false : null;
        },
        submitLocation(){
            this.setLocationValidator()
            if (!this.validateLocation()){
                return
            }
            this.creatingLocation = true;
            const url = make_url("/forms/practices/" + this.visit.practice_id + "/location_with_address");
            const self = this;
            const promise = authenticated_request({
                method: "post",
                url: url,
                data: this.newLocation
            }).then(function(response){
                if (response){
                    console.log("Practice location created");
                    console.log(response.data)
                    self.selectedLocation = {
                        location_id: response.data,
                        name: self.newLocation.location.name
                    }
                    self.selectableLocations.push(self.selectedLocation)
                }
            }).catch(function(error){
                console.log(error.response)
                self.showError(error);
            }).finally(()=>{
                self.creatingLocation = false;
                self.clearNewLocation()
            });
            return promise;
        },
        clearNewLocation(){
            this.newLocation = {
                "location": {
                    name: null,
                    ptan: null
                },
                "address": {
                    fax: null,
                    telephone: null,
                    address_line1: null,
                    city: null,
                    state: null,
                    zip: null,
                }
            },
            this.isNewLocation = false;
        },

        servicingProviderSearchChange: function(searchQuery) {
            this.servicingProvidersLoading = true;
            this.providerSearchText = searchQuery
            if (searchQuery.length > 0 ) {
                this.providerAutocomplete();
            } else {
                this.servicingProvidersLoading = false;
            }
        },
        resourceProviderSearchChange: function(searchQuery) {
            this.resourceProvidersLoading = true;
            this.providerSearchText = searchQuery
            if (searchQuery.length > 0 ) {
                this.providerAutocomplete();
            } else {
                this.resourceProvidersLoading = false;
            }
        },
        referringProviderSearchChange: function(searchQuery) {
            this.referringProvidersLoading = true;
            this.providerSearchText = searchQuery
            if (searchQuery.length > 0 ) {
                this.providerAutocomplete(true);
            } else {
                this.referringProvidersLoading = false;
            }
        },
        providerAutocomplete(include_referring_providers = false) {
            var vm = this;
            const url = make_url("/reports/providers/autocomplete");
            authenticated_request({
                method: "get",
                url: url,
                params: {
                    search: this.providerSearchText,
                    only_practice_providers: !include_referring_providers,
                    limit: 10
                }
            }).then(function(response){
                vm.servicingProvidersLoading = false;
                vm.resourceProvidersLoading = false;
                vm.referringProvidersLoading = false;

                const providers = response.data.providers;
                vm.selectableProviders = providers || [];
                return providers || [];
            }).catch(function(errors){
                vm.showError(errors);
            });
        },
        providerLabel(provider){
            return provider.full_name_with_suffix
        },
        referringProviderLabel(provider){
            return provider.full_name_with_suffix + " (NPI: " + (provider.npi ?? 'n/a') + ")"
        },

        clearVisit(){
            this.visit = {
                patient_id: null,
                practice_id: null,
                practice_location_id: null,
                servicing_provider_id: null,
                resource_provider_id: null,
                referring_provider_id: null,
                visit_type: null,
                visit_pos: null,
                start_time: null,
                end_time: null,
                date_of_service: null,
            };
            this.searchText = '';
            this.selectedPractice = null;
            this.practicesLoading = false;
            this.selectablePractices = [];
            this.clearNewLocation();
            this.clearVisitValidator();
        },
         //error methods
        showError(alertMessage){
            this.alertError = true;
            this.creatingLocation = false; // TODO: This doesn't belong here

            this.setShowAlert(true, alertMessage);
        },
        showSuccess(alertMessage){
            this.alertError = false;
            this.creatingLocation = false; // TODO: This doesn't belong here

            this.setShowAlert(true, alertMessage);
        },
        hideError(){
            this.setShowAlert(false);
        },
        fetchProcedureTypesMap(practice_id){
            const self = this;
            authenticated_request({
                method: "get",
                url: make_url("/lookup/visits/procedure_types/practice/" + practice_id)
            }).then(function(response){
                self.procedure_types = response.data['procedure_types'];
            }).then(()=>
            {
                self.procedureTypesMapLoading = false;
            });
        },
    },
    computed: {
        ...mapState('preauths', {
            patient: state => state.patient.patient,
            patientSubmitted: state => state.patient.submitted,
            insuranceSubmitted: state => state.insurance.insurancesSaved,
            visitSaved: state => state.visit.visitSaved,
            storedVisit: state => state.visit.visit,
        }),
        startDateTime: function() {
            if(this.dateOfService){
                var computedStartTime = this.startTime || '00:00';
                var temp = this.dateOfService + 'T' + computedStartTime;
                var newDate = new Date(temp)
                return newDate
            } return null
        },
        endDateTime: function() {
            if(this.dateOfService && this.endTime){
                var temp = this.dateOfService + 'T' + this.endTime;
                var newDate = new Date(temp)
                return newDate
            } return null
        },
    },
    watch: {
        patient(newValue){
            if(newValue){
                this.visit.patient_id = newValue.patient_id
            }
        },
        selectedPractice(newValue){
            if(newValue){
                console.log("Got new value for selected practice")
                this.visit.practice_id = newValue.practice_id;
                this.visit.practice_location_id = null;
                this.visit.visit_type = null;
                this.visit.visit_pos = null;
                this.startTime = null;
                this.endTime = null;
                this.dateOfService = null;
                this.selectedLocation = null;
                this.selectedServicingProvider = null;
                this.selectedResourceProvider = null;
                this.selectedReferringProvider = null;
                this.clearVisitValidator();
                this.clearNewLocation();
                this.locationAutocomplete();
                this.fetchProcedureTypesMap(newValue.practice_id);
            } else {
                this.visit.practice_id = null;
            }
        },
        selectedLocation(newValue){
            if(newValue){
                this.clearVisitValidator();
                this.clearNewLocation();
                this.visit.practice_location_id = newValue.location_id;
            } else {
                this.visit.practice_location_id = null;
            }
        },
        isNewLocation(value){
            if(value){
                this.selectedLocation = null;
            }
        },
        selectedServicingProvider(newValue){
            if(newValue){
                this.visit.servicing_provider_id = newValue.provider_id;
            } else {
                this.visit.servicing_provider_id = null;
            }
        },
        selectedResourceProvider(newValue){
            if(newValue){
                this.visit.resource_provider_id = newValue.provider_id;
            } else {
                this.visit.resource_provider_id = null;
            }
        },
        selectedReferringProvider(newValue){
            if(newValue){
                this.visit.referring_provider_id = newValue.provider_id;
            } else {
                this.visit.referring_provider_id = null;
            }
        },
        dateOfService(date){
            if(date){
                if (date.indexOf('T') == -1) {
                    this.visit.date_of_service = (date + "T00:00:00.000000+00:00");
                } else {
                    this.visit.date_of_service = date;
                }
            } else {
                this.visit.date_of_service = null;   
            }
        },
        startDateTime(datetime){
            if(datetime){
                this.visit.start_time = datetime;
            } else {
                this.visit.start_time = null;
            }
        },
        endDateTime(datetime){
            if(datetime){
                this.visit.end_time = datetime;
            } else {
                this.visit.end_time = null;
            }
        },
    },
    created(){
        this.debouncePractices = debounce(this.practiceSearchChange, 300)
        this.debounceLocations = debounce(this.locationSearchChange, 300)
        this.debounceServicingProviders = debounce(this.servicingProviderSearchChange, 300)
        this.debounceResourceProviders = debounce(this.resourceProviderSearchChange, 300)
        this.debounceReferringProviders = debounce(this.referringProviderSearchChange, 300)
    },
    async mounted(){
        var self = this;
        authenticated_request({
            method: "get",
            url: make_url("/lookup/visits/options")
        }).then(function(response){
            self.visitOptionMap = response.data
        });
        
    },
    beforeDestroy(){
        this.clearVisit()
    },
}
</script>

<style scoped>
.appointment-container{
    max-width: 1000px;
}
::v-deep .multiselect__spinner {
    background: #DDE4F5 !important;
}

::v-deep .multiselect--disabled {
    background: none;
    opacity: 1;
}
::v-deep .multiselect--disabled .multiselect__tags {
    background: #EEEFF3;
    border: 1px solid #ced4da;
    opacity: 1;
}
::v-deep .multiselect--disabled .multiselect__select {
    border-radius: 5px;
    background:none;
}

::v-deep .invalid .multiselect__tags {
    border:1px solid #DC3545;
    border-radius: 5px;
}
.new-practice-option {
    cursor: pointer;
    padding-bottom:8px;
    font-size:14px;
    display:flex;
    justify-content: center;
    align-items: center;
    border-top:1px solid #E2E4EB
}
.new-practice-option:hover {
    background-color:#EBF4FF;
}

/* Card styles */
.card {
    border: none;
    border-radius:15px !important;
    box-shadow: 1px 1px 10px #00000015;
}
.card-header {
    background: #F2F6FC 0% 0% no-repeat padding-box;
    border: none;
    margin:8px;
    border-radius:10px;
}
.card-body {
    padding: 10px 25px;
}
.card-body input{
    margin-bottom: 8px;
    font-size: 14px;
}

</style>