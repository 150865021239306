<template>
<div v-if="!patientSubmitted && !selected">
    <b-row>
        <b-col md="8" lg="6" class="d-flex align-items-center justify-content-between mb-3">
            <b-button @click="showNewPatientCard" class="avo-primary-btn">
                <b-icon icon="plus" class="mr-2"/>Add Patient
            </b-button>
        </b-col>
    </b-row>
    <div v-if="created">
        <b-card class="mb-3">
            <template #header>
                <strong>New Patient</strong>
                <div style="display:inline-block;float:right">
                    <b-button @click="clear" class="close-btn">
                        <b-icon icon="x" class="mr-2"/>
                    </b-button>
                </div>
            </template>
            <b-row align-v="end" class="mb-lg-3">
                <b-col md="6" lg="3">
                    <label>Patient's first name</label>
                    <b-form-input
                    v-model="newPatient.given_name"
                    :state="fieldStates('patient', 'given_name')"
                    placeholder="First name"/>
                    <b-form-invalid-feedback>{{fieldErrMessages('patient', 'given_name')}}</b-form-invalid-feedback>
                </b-col>
                <b-col md="6" lg="3">
                    <label>Last name</label>
                    <b-form-input
                    v-model="newPatient.family_name"
                    :state="fieldStates('patient', 'family_name')"
                    placeholder="First name"/>
                    <b-form-invalid-feedback>{{fieldErrMessages('patient', 'family_name')}}</b-form-invalid-feedback>
                </b-col>
                <b-col md="6" lg="3">
                    <label>Account number</label>
                    <b-form-input
                    v-model="newPatient.account_no"
                    placeholder="Acct #"/>
                </b-col>
                <b-col md="6" lg="3">
                    <label>DOB</label>
                    <b-form-input
                        v-model="newPatient.date_of_birth"
                        :state="fieldStates('patient', 'date_of_birth')"
                        type="date" placeholder="MM/DD/YYYY"/>
                    <b-form-invalid-feedback>{{fieldErrMessages('patient', 'date_of_birth')}}</b-form-invalid-feedback>
                </b-col>
            </b-row>
            <b-row align-v="end">
                <b-col md="6" lg="3">
                    <label>Primary phone</label>
                    <b-form-input
                    v-model="newAddress.telephone"
                    :state="fieldStates('address', 'telephone')"
                    type="tel" placeholder="XXX-XXX-XXXX"/>
                    <b-form-invalid-feedback>{{fieldErrMessages('address', 'telephone')}}</b-form-invalid-feedback>
                </b-col>
                <b-col md="6" lg="3">
                    <label>Email</label>
                    <b-form-input
                    v-model="newAddress.email"
                    :state="fieldStates('address', 'email')"
                    type="email" placeholder="email@email.com"/>
                    <b-form-invalid-feedback>{{fieldErrMessages('address', 'email')}}</b-form-invalid-feedback>
                </b-col>
                <b-col md="12" lg="6">
                    <b-form-checkbox v-model="isNewPatient" switch class="mb-3">
                        <span>New patient</span>
                    </b-form-checkbox>
                </b-col>
            </b-row>
        </b-card>
        <div class="d-flex">
            <b-button @click="addPatient" class="avo-primary-btn ml-md-3 ml-auto">Save New Patient</b-button>
        </div>
    </div>
</div>
</template>

<script>
import { make_url, authenticated_request } from '../../common.js'

export default {
    name: 'CreatePatient',
    data() {
		return {
            search: {
                terms: "",
            },
            newPatient: {
                patient_id: null,
                given_name: null,
                family_name: null,
                account_no: null,
                date_of_birth: null,
                full_name: null
            },
            newAddress: {
                email: null,
                telephone: null,
            },
            isNewPatient: null,

            patientValidator: {
                patient: {
                    given_name: null,
                    family_name: null,
                    date_of_birth: null,
                },
                address: {
                    email: null,
                    telephone: null,
                },
            },
		}
	},
    methods: {
        showNewPatientCard() {
            this.$store.commit("preauths/setDefaultPatient");
            this.$store.commit("preauths/patientCreated");
        },
        clear() {
            this.$store.commit("preauths/setDefaultPatient");
            this.newPatient = {
                given_name: null,
                family_name: null,
                account_no: null,
                date_of_birth: null}
            this.newAddress = {
                telephone: null,
                email: null
            }
            this.clearValidator();
        },
        clearValidator() {
            this.patientValidator = {
                patient: {
                    given_name: null,
                    family_name: null,
                    date_of_birth: null,
                },
                address: {
                    email: null,
                    telephone: null,
                },
            }
        },
        setValidator(payload) {
            this.patientValidator = {
                patient: {
                    given_name: payload['patient'] ? payload['patient']['given_name'] : null,
                    family_name: payload['patient'] ? payload['patient']['family_name'] : null,
                    date_of_birth: payload['patient'] ? payload['patient']['date_of_birth'] : null,
                },
                address: {
                    email: payload['address'] ? payload['address']['email'] : null,
                    telephone: payload['address'] ? payload['address']['telephone'] : null,
                },
            }
        },
        createFullName() {
            this.newPatient.full_name = this.newPatient.given_name;
            if (this.newPatient.full_name.length > 0)
            {
                if (this.newPatient.family_name && this.newPatient.family_name.length > 0){
                    this.newPatient.full_name = this.newPatient.full_name + " " + this.newPatient.family_name;
                }
            }
            else {
                this.newPatient.full_name = this.newPatient.family_name;
            }
        },
        setPatientState() {
            this.createFullName();
            this.$store.state.preauths.patient.patient = this.newPatient;
        },
        setAddressState() {
            this.$store.state.preauths.patient.address = this.newAddress;
        },
        setNewPatientFlag(value) {
            this.$store.commit("preauths/setNewPatientFlag", value)
        },
        addPatient() {
            const params = {
                'patient': {
                    'given_name': this.newPatient.given_name ? this.newPatient.given_name : null,
                    'family_name': this.newPatient.family_name ? this.newPatient.family_name : null,
                    'date_of_birth': this.newPatient.date_of_birth,
                    'account_no': this.newPatient.account_no
                },
                'address': {
                    'telephone': this.newAddress.telephone ? this.newAddress.telephone : null,
                    'email': this.newAddress.email ? this.newAddress.email : null
                }
            };
            const url = make_url("/forms/patient_with_address");
            const vm = this;
            const promise = authenticated_request({
                method: "post",
                url: url,
                data: params
            }).then(function(response){
                if (response) {
                    vm.newPatient.patient_id = response.data
                    vm.setPatientState();
                    vm.setAddressState();
                    vm.setNewPatientFlag(vm.isNewPatient);
                    vm.$store.commit("preauths/patientSelected");
                }
            }).catch(function(error){
                vm.handleErrors(error);
            });
            return promise;
        },
        handleErrors(error) {
            this.$store.commit("preauths/hideAlerts");
            var messages = [];
            if (error.response.status == 400) {
                // handles field validation
                if ("payload" in error.response.data) {
                    const payload = error.response.data.payload;
                    this.setValidator(payload);
                }
                // handles any other custom 400 errors that may occur
                else {
                    messages.push(error.response.data.msg);
                }
            }
            this.$store.commit("preauths/displayAlerts", messages);
        },
        fieldStates(object, field) {
            return this.patientValidator[object][field] ? false : null;
        },
        fieldErrMessages(object, field) {
            return this.patientValidator[object][field] ? this.patientValidator[object][field][0] : null;
        }
    },
    computed: {
        created() {
            return this.$store.state.preauths.patient.created;
        },
        patientSubmitted() {
            return this.$store.state.preauths.patient.submitted;
        },
        selected() {
            return this.$store.state.preauths.patient.selected;
        }
    },
    watch: {
        selected: function() {
            if (!this.selected) {
                this.clear();
            }
            else {
                this.$store.commit("preauths/hideAlerts");
            }
        },
    }
}
</script>

<style scoped>

/* Card styles */
.card {
    border: none;
    border-radius:15px !important;
    box-shadow: 1px 1px 10px #00000015;
}
.card-header {
    background: #F2F6FC 0% 0% no-repeat padding-box;
    border: none;
    margin:8px;
    border-radius:10px;
}
.card-body {
    padding: 10px 25px;
}
.card-body input{
    margin-bottom: 8px;
    font-size: 14px;
}

/* Button styles */
.avo-primary-btn,
.avo-primary-btn:focus  {
    height:40px;
    max-width:150px;
    box-shadow: none !important;
    border: 2px solid var(--blue);
    border-radius: 5px;
    background: white;
    color: var(--blue);
    font-size: 14px;
}
.avo-primary-btn:hover{
    background: #EEF1FA 0% 0% no-repeat padding-box;
}
.avo-primary-btn:active{
    border: none !important;
    background: var(--blue) 0% 0% no-repeat padding-box !important;
    color: white !important;
}
.close-btn {
    position:relative;
    width: 24px; 
    height: 24px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 3px #1617181A;
    border: none;
    border-radius: 12px; 
    color: var(--gray-800);
    font-size: 1rem; 
}
.close-btn:hover {
    background: #F2F6FC 0% 0% no-repeat padding-box;
}
.close-btn:active,
.close-btn.active {
    background: #DDE4F5 0% 0% no-repeat padding-box !important;
    color: var(--gray-800) !important;
    box-shadow: 0px 2px 3px #1617181A !important;
}
.close-btn .b-icon {
    position:absolute;
    top:50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%); 
}
</style>