<template id="preauthAddAuthInfo">
<div>
<div class="auth-info-container mb-5">
    <b-row>
        <b-col cols="4">
            <label class="selecter-label">Status</label>
            <b-form-select
                v-model="info.preauth_state"
                :options="preauthStateMap">
                <template #first>
                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col cols="4">
            <label class="selecter-label">Effective start date</label>
            <b-form-input type="date" 
            v-model="info.effective_start_date" 
            placeholder="Choose a date"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" />
        </b-col>
        <b-col cols="4">
            <label class="selecter-label">Effective end date</label>
            <b-form-input type="date" 
            v-model="info.effective_end_date" 
            placeholder="Choose a date"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" />
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="4">
            <label class="selecter-label">Insurance authorization no.</label>
            <b-form-input v-model="info.insurance_auth_no"></b-form-input>
        </b-col>
        <b-col cols="4">
            <label class="selecter-label">Reference no.</label>
            <b-form-input v-model="info.reference_no"></b-form-input>
        </b-col>
        <b-col cols="4">
            <label class="selecter-label">No. visits/units</label>
            <b-row>
                <b-col cols="6">
                        <b-form-input type="number" v-model="info.quantity_approved"></b-form-input>
                        <div class="avo-text-light ml-2">Approved</div>
                </b-col>
                <b-col cols="6 pl-0">
                        <b-form-input type="number" v-model="info.quantity_remaining"></b-form-input>
                        <div class="avo-text-light ml-2">Remaining</div>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</div>
</div>
</template>

<script>
export default {
  name: 'PreauthAddAuthInfo',
  template: "#preauthAddAuthInfo",
  props: [],
  data() {
      return {
            info: {
                preauth_state: null,
                insurance_auth_no: null,
                reference_no: null,
                quantity_approved: null,
                quantity_remaining: null,
                effective_start_date: null,
                effective_end_date: null,
            },
      }
  },
  computed: {
    preauthStateMap() {
      return this.$store.state.preauths.preauth.preauthStateMap;
    },
  },
  watch: {
      info: {
            handler(newValue){
                const newData = { preauth_state: newValue.preauth_state,
                    insurance_auth_no: newValue.insurance_auth_no,
                    reference_no: newValue.reference_no,
                    quantity_approved: newValue.quantity_approved,
                    quantity_remaining: newValue.quantity_remaining,
                    effective_start_date: newValue.effective_start_date ? newValue.effective_start_date + "T00:00:00+00:00" : null,
                    effective_end_date: newValue.effective_end_date ? newValue.effective_end_date + "T00:00:00+00:00" : null,
                };
                this.$store.commit("preauths/setAuthInfo", newData);
            },
            deep: true
      }
  },
}
</script>

<style scoped>
.auth-info-container{
    max-width: 1000px;
}
</style>