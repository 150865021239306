<template>
    <div v-if="patient">
        <div v-if="!patientSubmitted && selected">
            <br>
            <div class="info">Please confirm this is the correct patient. If not, please remove and select a new patient</div>
            <b-card-group style="margin-top:10px;max-width:1000px">
                <b-card class="patient-select shadow">
                    <template #header>
                            <b-icon icon="check-circle" font-size="1.5rem" class="mr-2"></b-icon>
                            Patient Selected
                            <div style="display:inline-block;float:right">
                                <b-button @click="clearPatient" class="close-btn">
                                    <b-icon icon="x" class="mr-2"></b-icon>
                                </b-button>
                            </div>
                            <div class="card-top-body">
                                <b-table 
                                class="patient-table"
                                :items="[patient]" 
                                :fields="patientDisplayFields"
                                :borderless="true">
                                </b-table>
                            </div>
                    </template>
                    <b-container style="margin-top:30px">
                        <b-row>
                            <b-col>
                                <label>Primary phone</label>
                                <b-form-input
                                v-model="newTelephone"
                                :state="fieldStates('telephone')"/>
                                <b-form-invalid-feedback>{{fieldErrMessages('telephone')}}</b-form-invalid-feedback>
                            </b-col>
                            <b-col>
                                <label>Email</label>
                                <b-form-input
                                type="email" v-model="newEmail"
                                :state="fieldStates('email')"/>
                                <b-form-invalid-feedback>{{fieldErrMessages('email')}}</b-form-invalid-feedback>
                            </b-col>
                            <b-col>
                            <b-form-checkbox v-model="newPatient" switch style="position:absolute;bottom:0;">
                                <span>New P.T.</span>
                            </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-card>
            </b-card-group>
        </div>
        <div v-else-if="patientSubmitted">
            <br>
            <b-card class="patient-display shadow">
                <b-container>
                    <b-row>
                        <div class="display-header">
                            {{patient.full_name}}
                        </div>
                    </b-row>
                    <br>
                    <b-row>
                        <b-col>
                            <b-row>
                                <div class="display-light"> Account No. </div>
                            </b-row>
                            <b-row>
                                #{{patient.account_no}}
                            </b-row>
                        </b-col>
                        <b-col>
                            <b-row>
                                <div class="display-light"> DOB </div>
                            </b-row>
                            <b-row>
                                {{formatDateShort(patient.date_of_birth)}}
                            </b-row>
                        </b-col>
                        <b-col>
                            <b-form-checkbox v-model="newPatient" switch disabled style="position:absolute;bottom:0;">
                                <span>New patient</span>
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <br><br>
                    <b-row>
                        <div class="display-light">
                            <b-icon icon="telephone" style="margin-right:5px;"></b-icon> 
                            {{newTelephone}}
                        </div>
                    </b-row>
                    <b-row>
                        <div class="display-light">
                            <b-icon icon="envelope" style="margin-right:5px;"></b-icon>
                            {{newEmail}}
                        </div>
                    </b-row>
                </b-container>
            </b-card>
        </div>
    </div>
</template>

<script>
import { make_url, 
    authenticated_request, 
    formatDateShort } from '../../common.js'
    
import { mapState } from 'vuex'

export default {
    name: 'PatientDisplay',
    data() {
        return {
            newTelephone: "",
            newEmail: "",
            patientDisplayFields: [
                {
                    key: "full_name",
                    label: "Patient"
                },
                {
                    key: "account_no",
                    label: "Account #"
                },
                {
                    key: "date_of_birth",
                    label: "DOB"
                }
            ],
            addressValidator: {
                email: null,
                telephone: null,
            },
        }
    },
    methods: {
        clearPatient: function() {
            this.$store.commit("preauths/setDefaultPatient");
            this.addressValidator = {
                email: null,
                telephone: null,
            }
        },
        submitSelectedPatient: function() {
            //modify patient address if changed
            if ((this.address['telephone'] != this.newTelephone) ||
                (this.address['email'] != this.newEmail)) {
                    const params = {
                        'telephone': this.newTelephone ? this.newTelephone : null,
                        'email': this.newEmail ? this.newEmail : null
                    };
                    const patient_id = this.patient.patient_id;
                    const vm = this;
                    const url = make_url("/patients/" + patient_id + "/address");
                    const promise = authenticated_request({
                        method: "put",
                        url: url,
                        data: params
                    }).then(function(response){
                        if (response) {
                            vm.$store.commit("preauths/validSubmitPatient");
                        }
                    }).catch(function(error){
                        vm.handleErrors(error);
                    });
                    return promise;
                }
            this.$store.commit("preauths/validSubmitPatient");
        },
        handleErrors(error) {
            this.$store.commit("preauths/hideAlerts");
            var messages = [];
            if (error.response.status == 400) {
                // handles field validation
                if ("payload" in error.response.data) {
                    const payload = error.response.data.payload;
                    this.addressValidator = {
                        email: payload['email'],
                        telephone: payload['telephone'],
                    }
                }
                // handles any other custom 400 errors that may occur
                else {
                    messages.push(error.response.data.msg);
                }
            }
            this.$store.commit("preauths/displayAlerts", messages);
        },
        fieldStates(field) {
            return this.addressValidator[field] ? false : null;
        },
        fieldErrMessages(field) {
            return this.addressValidator[field] ? this.addressValidator[field][0] : null;
        },
        formatDateShort(datetime) {
          return formatDateShort(datetime);
        },
    },
    computed: {
        ...mapState('preauths', {
            patient: state => state.patient.patient,
            address: state => state.patient.address,
            trySubmit: state => state.patient.trySubmit,
            selected: state => state.patient.selected,
            patientSubmitted: state => state.patient.submitted,
        }),
        newPatient: {
            get() {
                return this.$store.state.preauths.patient.isNewPatient;
            },
            set(value) {
                this.$store.state.preauths.patient.isNewPatient = value;
            }
        }
    },
    watch: {
        address: function(){
            if (this.address) {
                this.newTelephone = this.address.telephone
                this.newEmail = this.address.email
            }
            else {
                return {
                    "telephone": "",
                    "email": ""
                    }
            }
        },
        trySubmit: function() {
            if (this.trySubmit) {
                this.$store.commit("preauths/setTrySubmitPatient", false);
                if (this.selected && this.patient && this.address) {
                    this.submitSelectedPatient();
                }
            }
        },
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
/* Patient select card styles */
.card {
    border: none;
    border-radius:15px !important;
    box-shadow: 1px 1px 10px #00000015;
}
.patient-select .card-header {
    background: #F2F6FC 0% 0% no-repeat padding-box;
    border: none;
    margin:8px;
    border-radius:10px;
    font-size: 16px;
    font-weight: bold;
}
.patient-select .card-body {
    padding: 0px 0 25px;
    color:var(--dark);
}
.patient-select .card-body input{
    margin-bottom: 8px;
    font-size: 14px;
}
.patient-select .card-top-body {
    padding:30px;
}

/* Close button styles */
.close-btn {
    position:relative;
    width: 24px; 
    height: 24px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 3px #1617181A;
    border: none;
    border-radius: 12px; 
    color: var(--gray-800);
    font-size: 1rem; 
}
.close-btn:hover {
    background: #F2F6FC 0% 0% no-repeat padding-box;
}
.close-btn:active,
.close-btn.active {
    background: #DDE4F5 0% 0% no-repeat padding-box !important;
    color: var(--gray-800) !important;
    box-shadow: 0px 2px 3px #1617181A !important;
}
.close-btn .b-icon {
    position:absolute;
    top:50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%); 
}

/* Patient table styles */
::v-deep .patient-table {
    width: auto;
    margin-bottom: 0rem;
    border-spacing: 1px;
}
::v-deep .patient-table td,
::v-deep .patient-table th {
    padding-top: 0rem;
    padding-bottom: .5rem;
    padding-right:3rem;
}
::v-deep .patient-table th {
    font-size: 14px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color:#9CA3AD;
}
::v-deep .patient-table td {
    font-size: 15px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color:var(--dark);
}

/* Patient Display card styles */
.patient-display {
    background: transparent linear-gradient(102deg, #223053 0%, #33487D 100%) 0% 0% no-repeat padding-box;
    font-size: 14px;
    color:white;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    max-width:500px;
    border-radius:20px;
    padding-left:10px;
}
.display-header {
    font-size: 20px;
    font-weight: 500;
}
.info {
    font-size: 14px;
    color: var(--gray);
    font-family: "Roboto", sans-serif;
}
.display-light {
    opacity: .6;
}
</style>