<template>
<div>
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div class="mb-2" style="font-size:18px;">Diagnosis Codes</div>
    <b-overlay :show="icdCodesLoading" variant="white">
    <b-table-simple class="shadow mb-4" style="border:solid 1px #E2E4EB;font-size:14px;background-color:white;">
        <b-thead>
            <b-tr>
                <b-th>
                    ICD-10
                </b-th>
                <b-th>
                    Description
                </b-th>
                <b-th>
                    Pointer
                </b-th>
                <b-th>
                </b-th>
            </b-tr>
        </b-thead>
        <b-tbody>
            <b-tr v-for="(code, index) in icdCodes" :key=index>
                <b-td>
                    <div class="mt-2">
                        {{code.code || "-"}}
                    </div>
                </b-td>
                <b-td style="overflow-wrap:break-word">
                    <div class="mt-2">
                        {{code.description || "N/A"}}
                    </div>
                </b-td>
                <b-td>
                    <div class="mt-2">
                        {{code.pointer || "-"}}
                    </div>
                </b-td>
                <b-td class="text-right">
                    <b-dropdown class="avo-action-dropdown" no-caret style="min-width:none" :right="true">
                        <template #button-content>
                        <b-icon icon="three-dots"></b-icon>
                        </template>
                        <b-dropdown-item @click="viewEditICDModal(index)">Edit pointer</b-dropdown-item>
                        <b-dropdown-item @click="deleteICDCode(index)">Remove</b-dropdown-item>
                        <b-modal :id="'edit-icd-modal-'+index" hide-footer hide-header>
                            <div class="avo-header-text" style="text-align:center">
                                Edit ICD Code Pointer
                            </div>
                            <hr>
                            <b-alert
                                v-model="showAlertModal"
                                variant="danger"
                                dismissible>
                                <b>{{errorTextModal}}</b>
                            </b-alert> 
                            <b-row>
                                <b-col>
                                    <b-form-group
                                    id="pointer"
                                    label="Pointer:">
                                    <b-input-group>
                                        <b-form-input type="number" min="0" v-model="newPointer"></b-form-input>
                                    </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col style="text-align:right">
                                    <b-button @click="closeEditICDModal(index)" class="avo-basic-btn mr-2">
                                        Cancel
                                    </b-button>
                                    <b-button @click="submitICDEdit(index)" class="avo-primary-btn">
                                        Submit New Pointer
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-modal>
                    </b-dropdown>
                </b-td>
            </b-tr>
            <b-tr>
                <b-td colspan="4">
                    <div>
                        <b-button v-b-modal.new-icd-code class="avo-primary-btn mr-2" style="width:150px;height:30px">
                            <b-icon class="mr-2" icon="plus-circle"></b-icon>
                            Add ICD code
                        </b-button>
                        <icd-code-add :newPreauth="true" :current-codes="icdCodes" />
                    </div>
                </b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
    </b-overlay>
    <div class="mb-2" style="font-size:18px;">CPT Codes</div>
    <b-overlay :show="cptCodesLoading" variant="white">
    <b-table-simple class="shadow" style="border:solid 1px #E2E4EB;font-size:14px;background-color:white;">
        <b-thead>
            <b-tr>
                <b-th>
                    CPT Codes
                </b-th>
                <b-th>
                    Modifiers
                </b-th>
                <b-th>
                    Description
                </b-th>
                <b-th class="text-right">
                    Charge 
                </b-th>
                <b-th>
                    Units
                </b-th>
                <!-- <b-th>
                    Pointers
                </b-th> -->
                <b-th>
                    POS
                </b-th>
                <b-th>
                    TOS
                </b-th>
                <b-th class="text-right">
                    Total
                </b-th>
                <b-th>
                </b-th>
            </b-tr>
        </b-thead>
        <b-tbody>
            <b-tr v-for="(code, index) in cptCodes" :key=index>
                <b-td>
                    <div class="mt-2">
                        {{code.code}}: <span class="avo-text-light-small">{{(code.short || '-')}}</span>
                    </div>
                </b-td>
                <b-td>
                    <div v-if="code.modifiers">
                        <b-badge v-if="code.modifiers.length > 0 && code.modifiers[0]" class="mr-2 avo-badge" style="min-width:auto!important;padding:5px;font-size:10px;">
                            {{code.modifiers[0]}}
                        </b-badge>
                        <b-badge v-if="code.modifiers.length > 1 && code.modifiers[1]" class="mr-2 mt-1 avo-badge" style="min-width:auto!important;padding:5px;font-size:10px;">
                            {{code.modifiers[1]}}
                        </b-badge>
                        <b-badge v-if="code.modifiers.length > 2 && code.modifiers[2]" class="mr-2 mt-1 avo-badge" style="min-width:auto!important;padding:5px;font-size:10px;">
                            {{code.modifiers[2]}}
                        </b-badge>
                        <b-badge v-if="code.modifiers.length > 3 && code.modifiers[3]" class="mr-2 mt-1 avo-badge" style="min-width:auto!important;padding:5px;font-size:10px;">
                            {{code.modifiers[3]}}
                        </b-badge>
                    </div>
                </b-td>
                <b-td style="max-width:425px;width:425px;overflow-wrap:break-word">
                    <span class="avo-text-light-small">{{code.medium || "-"}}</span>
                </b-td>
                <b-td>
                    <div class="mt-2 text-right">
                        ${{Number(code.unit_charge).toFixed(2)}}
                    </div>
                </b-td>
                <b-td>
                    <div class="mt-2">
                        {{code.units || 0}}
                    </div>
                </b-td>
                <b-td>
                    <div class="mt-2">
                        {{code.pos || '-'}}
                    </div>
                </b-td>
                <b-td>
                    <div class="mt-2">
                        {{code.tos || '-'}}
                    </div>
                </b-td>
                <b-td>
                    <div class="mt-2 text-right">
                        ${{Number(((code.unit_charge || 0) * (code.units || 0))).toFixed(2)}}
                    </div>
                </b-td>
                <b-th>
                    <b-dropdown class="avo-action-dropdown" no-caret style="min-width:none" :right="true">
                        <template #button-content>
                        <b-icon icon="three-dots"></b-icon>
                        </template>
                        <b-dropdown-item @click="viewEditCPTModal(index)">Edit</b-dropdown-item>
                        <b-dropdown-item @click="deleteCPTCode(index)">Remove</b-dropdown-item>
                        <b-modal :id="'edit-cpt-modal-'+index" hide-footer hide-header>
                            <div class="avo-header-text" style="text-align:center">
                                Edit CPT Code
                            </div>
                            <hr>
                            <b-alert
                                v-model="showAlertModal"
                                variant="danger"
                                dismissible>
                                <b>{{errorTextModal}}</b>
                            </b-alert> 
                            <b-row>
                                <b-col>
                                    <b-form-group
                                    id="units"
                                    label="Units:">
                                    <b-input-group>
                                        <b-form-input type="number" v-model="editCPTData['units']"></b-form-input>
                                    </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group
                                    id="unit-charge"
                                    label="Unit charge:">
                                    <b-input-group prepend="$">
                                        <b-form-input type="number" v-model="editCPTData['unit_charge']"></b-form-input>
                                    </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-form-group
                                    id="pos"
                                    label="POS:">
                                    <b-input-group>
                                        <b-form-input type="number" v-model="editCPTData['pos']"></b-form-input>
                                    </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group
                                    id="tos"
                                    label="TOS:">
                                    <b-input-group>
                                        <b-form-input type="number" v-model="editCPTData['tos']"></b-form-input>
                                    </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col style="text-align:right">
                                    <b-button @click="closeEditCPTModal(index)" class="avo-basic-btn mr-2">
                                        Cancel
                                    </b-button>
                                    <b-button @click="submitCPTEdit(index)" class="avo-primary-btn">
                                        Submit CPT Code Changes
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-modal>
                    </b-dropdown>
                </b-th>
            </b-tr>
            <b-tr>
                <b-td colspan="12">
                    <div class="d-flex justify-content-between">
                    <div>
                        <b-button v-b-modal.new-cpt-code class="avo-primary-btn mr-2" style="width:150px;height:30px">
                            <b-icon class="mr-2" icon="plus-circle"></b-icon>
                            Add CPT code
                        </b-button>
                        <cpt-code-add :newPreauth="true" :practice-id="practiceId" :current-codes="cptCodes" :selectablePointers="pointers" />
                    </div>
                    <div style="margin-top:10px">
                        <span class="mr-3">
                            Total charged:
                        </span>
                        <span class="bold">
                            ${{totalCharged}}
                        </span>
                    </div>
                    </div>
                </b-td>
                <b-td></b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
    </b-overlay>
    <b-row v-if="!codesSaved">
        <b-col class="text-right mt-4">
            <b-button class="avo-primary-btn" variant="outline-primary" @click="saveCodes()">Continue</b-button>
        </b-col>
    </b-row>
</div>
</template>

<script>
import IcdCodeAdd from '../codes/IcdCodeAdd'
import CptCodeAdd from '../codes/CptCodeAdd'
import { alertsMixin } from '@/common'


export default {
    name: 'PreauthAddCodes',
    components: {
        IcdCodeAdd,
        CptCodeAdd,
    },
    mixins: [ alertsMixin ],
    props: ['practiceId',],
    data() {
		return {
            cptCodesLoading: false,
            cptCodes: [],
            pointers: [],
            editCPTData: {},

            icdCodesLoading: false,
            icdCodes: [],
            header_checkbox: false,
            row_checkbox: [],
            showDeleteButton: false,
            newPointer: 0,
            requests: 0,
            
            showAlertModal: false,
            errorTextModal: "",
            
		}
	},
    beforeDestroy(){
    },
    methods: {
        saveCodes(){
            console.log("saving codes")
            if(this.icdCodes.length == 0 && this.cptCodes.length == 0){
                this.setShowAlert(true, "Please attach at least one ICD or CPT code to continue");
            } else {
                this.setShowAlert(false);
                this.$store.commit("preauths/saveCodes");
            }
        },

        deleteICDCode: function(index){
            if(index > -1){
                var codePointer = this.icdCodes[index].pointer;
                var pointerIndex = this.pointers.indexOf(codePointer);
                if (pointerIndex !== -1) {
                    this.pointers.splice(pointerIndex, 1);
                    this.$store.commit("preauths/storePointerList", this.pointers);
                }
                this.icdCodes.splice(index, 1)
                this.$store.commit("preauths/storeICDCodesList", this.icdCodes);
            }
        },
        viewEditICDModal(index){
            this.newPointer = this.icdCodes[index].pointer;
            this.$bvModal.show('edit-icd-modal-'+index);
        },
        closeEditICDModal(index){
            this.newPointer = 0;
            this.errorTextModal = "";
            this.showAlertModal = false;
            this.$bvModal.hide('edit-icd-modal-'+index);
        },
        submitICDEdit(index){
            this.icdCodes[index].pointer = this.newPointer;
            this.$store.commit("preauths/storeICDCodesList", this.icdCodes);
            this.closeEditICDModal(index);
        },

        deleteCPTCode: function(index){
            if(index > -1){
                this.cptCodes.splice(index, 1)
                this.$store.commit("preauths/storeCPTCodesList", this.cptCodes);
            }
        },
        createPointersString(pointer_array){
            if (pointer_array && (pointer_array.length > 0)){
                return pointer_array.join(", "); 
            }
            return "-"
        },
        viewEditCPTModal(index){
            this.editCPTData = {
                units: (this.cptCodes[index].units || 0),
                unit_charge: (this.cptCodes[index].unit_charge || 0),
                pos: (this.cptCodes[index].pos || 0),
                tos: (this.cptCodes[index].tos || 0),
                pointers: (this.cptCodes[index].pointers || []),
            }
            this.$bvModal.show('edit-cpt-modal-'+index);
        },
        closeEditCPTModal(index){
            this.editCPTData = {}
            this.errorTextModal = "";
            this.showAlertModal = false;
            this.$bvModal.hide('edit-cpt-modal-'+index);
        },
        submitCPTEdit(index){
            this.cptCodes[index].units = this.editCPTData.units;
            this.cptCodes[index].unit_charge = this.editCPTData.unit_charge;
            this.cptCodes[index].pos = this.editCPTData.pos;
            this.cptCodes[index].tos = this.editCPTData.tos;
            this.cptCodes[index].pointers = this.editCPTData.pointers;

            this.$store.commit("preauths/storeCPTCodesList", this.cptCodes);
            this.closeEditCPTModal(index);
        },

    },
    computed: {
        totalCharged: function(){
            var total = 0;
            if (this.cptCodes.length > 0){
                for (var i = 0; i < this.cptCodes.length; i++){
                    if (this.cptCodes[i].unit_charge && this.cptCodes[i].units){
                        total = total + (this.cptCodes[i].units * this.cptCodes[i].unit_charge);
                    }
                }
            }
            return Number(total).toFixed(2);
        },
        storedICDCodes(){
            return this.$store.state.preauths.codes.storedICDs;
        },
        storedPointers(){
            return this.$store.state.preauths.codes.storedPointers;
        },
        storedCPTCodes(){
            return this.$store.state.preauths.codes.storedCPTs;
        },
        codesSaved(){
            return this.$store.state.preauths.codes.codesSaved;
        }
    },
    watch: {
        storedICDCodes(newCodeList){
            this.icdCodes = newCodeList;
            if(newCodeList.length > 0){
                this.setShowAlert(false);
            }
        },
        storedCPTCodes(newCodeList){
            this.cptCodes = newCodeList;
            if(newCodeList.length > 0){
                this.setShowAlert(false);
            }
        },
        storedPointers(newPointerList){
            this.pointers = newPointerList;
        },
    },
    async mounted() {

    }
}
</script>

<style scoped>
.alert {
    background: #FDEEED 0% 0% no-repeat padding-box;
    border: 1px solid var(--red);
    border-radius: 5px;
    text-align: center;
    letter-spacing: 0px;
    color: var(--red);
}

</style>