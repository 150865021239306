<template>
<div>
<b-row class="mb-4">
   <b-col>
   <div class="data-label">Primary Insurance:</div> 
   <div>{{name}}</div>
   </b-col>
</b-row>
<b-row>
    <b-col>
       <div class="data-label">Preauths Submission Portal</div>
       <div> 
        {{preauths_portal || '-'}}
       </div>
    </b-col>
    <b-col>
       <div class="data-label">Submission Phone</div>
       <div> 
        {{preauths_phone || '-'}}
       </div>
    </b-col>
    <b-col>
       <div class="data-label">Submission Fax</div>
       <div> 
        {{preauths_fax || '-'}}
       </div>
    </b-col>
</b-row>
</div>
    
</template>

<script>
export default {
    name: 'PayerPreauthDetails',
    props: ['payer',],
    computed: {
         name(){
            return this.payer?.name || 'No primary insurance found';
         },
         preauths_portal(){
            return this.payer?.preauths_portal || '-';
         },
         preauths_phone(){
            return this.payer?.preauths_phone || '-';
         },
         preauths_fax(){
            return this.payer?.preauths_fax || '-';
         },
    }
}
</script>
