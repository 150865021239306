




























































































































































































































































































































import Vue from 'vue'
import { make_url, authenticated_request, makeDateTime } from '../../common.js'
import InsuranceAdd from '../payers/InsuranceAdd.vue'

export default Vue.extend({
    name: 'PatientInsurancePreauth',
    components: {
        InsuranceAdd,
    },
    data() {
        return {
            insurances: [] as any[],
            toggled: [] as Boolean[],
            primary: -1,
            previousPrimary: -1,
            showPayerSelection: false,
            insurancesSubmitted: false,
            payerDisplayFields: [
                {
                    key: "name",
                    label: "Insurance provider",
                    formatter: (this as any).defaultText,
                },
                {
                    key: "telephone",
                    label: "Phone",
                    formatter: (this as any).defaultText,
                },
                {
                    key: "fax",
                    label: "Fax",
                    formatter: (this as any).defaultText,
                },
                {
                    key: "full_address",
                    label: "Address",
                    formatter: (this as any).defaultText,
                },
                {
                    key: "payer_id",
                    label: "ID",
                }
            ]
        }
    },
    methods: {
        getPatientInsurances: function() {
            const url = make_url("/patients/reports/" + this.patient.patient_id + "/insurance");
            const self = this;
            const promise = authenticated_request({
                method: "get",
                url: url
            }).then(function(response: any){
                var insurances: any[] = response.data.insurance;
                // converts datetimes back to dates for preloading the effective date inputs
                insurances.forEach(function (item, index) {
                    if(item.effective_start_date){
                        insurances[index]['effective_start_date'] = item.effective_start_date.split('T')[0];
                    }
                    if(item.effective_end_date){
                        insurances[index]['effective_end_date'] = item.effective_end_date.split('T')[0];
                    }
                    
                });
                self.insurances = insurances;
                
                return insurances;
            });
            
            return promise;
        },
        addInsurance: function() {
            //TODO: implement when search patient insurance component is made
            this.showPayerSelection = true;
        },
        removeInsurance: function(payer_id: number, index: number) {
            this.insurances.splice(index, 1);
            
            // we dont actually want to delete patient insurances here, merely remove from selection 
        },
        createRequestData: function() {
            const len = this.insurances.length;
            var data = new Array(len);
            for (var index = 0; index < len; index++)
            {
                data[index] = {
                    'payer_id': this.insurances[index].payer_id,
                    'member_id': this.insurances[index].member_id,
                    'group_no': this.insurances[index].group_no,
                    'effective_start_date': makeDateTime(this.insurances[index].effective_start_date),
                    'effective_end_date': makeDateTime(this.insurances[index].effective_end_date),
                    'subscriber_name': this.insurances[index].subscriber_name,
                    'subscriber_relationship': this.insurances[index].subscriber_relationship,
                    'active_benefits': this.insurances[index].active_benefits,
                    'copay': this.insurances[index].copay || "0.00",
                    'deductible': this.insurances[index].deductible || "0.00",
                    'out_of_pocket': this.insurances[index].out_of_pocket || "0.00",
                    'patient_responsibility': this.insurances[index].patient_responsibility || "0",
                    'patient_responsibility_type': this.insurances[index].patient_responsibility_type,
                }
            }
            return data;
        },
        submitInsurances: function() {
            const url = make_url("/patients/" + this.patient.patient_id + "/payers");
            var self = this;
            var data = this.createRequestData();
            const promise = authenticated_request({
                method: "put",
                url: url,
                data: data
            }).then(function(response){
                self.insurancesSubmitted = true;
                self.$store.commit("preauths/setInsurances", self.insurances);
                return response;
            });
            
            return promise;
        },
        defaultText: function(value: String | null): String {
            if (!value){
                return "N/A";
            }
            return value;
        },
        toggleRow: function(index: number) {
            this.$set(this.toggled, index, !this.toggled[index]);
        },
        // handlePrimaryChange: function() {
        //     this.modifyPrimaryInsurance(this.primary, true);
        //     if (!(this.previousPrimary == -1)) {
        //         this.modifyPrimaryInsurance(this.previousPrimary, false);
        //     }
        //     this.previousPrimary = this.primary;
        // },
        // modifyPrimaryInsurance: function(index: number, value: Object) {
        //     var payer_id = this.insurances[index].payer_id;
        //     const url = make_url("/patients/" + this.patient.patient_id + "/payers/" + payer_id).toString();
        //     var data = {
        //         'primary_insurance': value
        //     };
        //     const promise = authenticated_request({
        //         method: "put",
        //         url: url,
        //         data: data
        //     }).then(function(response){
        //         return response;
        //     });
        //     return promise;
        // },
        insuranceStatus: function(index: number) {
            var today = new Date().toISOString().slice(0, 10);
            if ((this.insurances[index].effective_start_date && this.insurances[index].effective_end_date) && 
                (today > this.insurances[index].effective_start_date && today < this.insurances[index].effective_end_date) &&
                this.insurances[index].active_benefits)
            {
                return "Effective";
            }
            else {
                return "Not effective";
            }
        }
    },
    computed: {
        patient(): any {
            return this.$store.state.preauths.patient.patient;
        },
        patientSubmitted(): Boolean {
            return this.$store.state.preauths.patient.submitted;
        }
    },
    watch: {
        patientSubmitted: function() {
            if (this.patientSubmitted) {
                this.getPatientInsurances();
            }
            else {
                this.insurances = [];
                this.toggled = [];
                this.primary = -1;
                this.previousPrimary = -1;
            }
        },
        insurances: function() {
            if(this.insurances.length > 0){
                for (var index = 0; index < this.insurances.length; index++) {
                    if (this.insurances[index].primary_insurance) {
                        this.primary = index;
                        this.previousPrimary = index;
                        break;
                    }
                }

            }
        },
        insurancesSubmitted: function() {
            this.showPayerSelection = false;
        }
    }
})
