<template>
<div id="preauth-edit">
<b-modal id="preauth-edit" title="Edit Preauth" hide-footer size="lg">
<b-form>
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div>
        <b-row>
        <b-col cols="4">
            <label class="selecter-label">Status</label>
            <b-form-select
                v-model="preauth_state"
                :options="preauthStateMap">
                <template #first>
                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col cols="4">
            <label class="selecter-label">Effective start date</label>
            <b-form-input type="date" 
            v-model="effective_start_date" 
            placeholder="Choose a date"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" />
        </b-col>
        <b-col cols="4">
            <label class="selecter-label">Effective end date</label>
            <b-form-input type="date" 
            v-model="effective_end_date" 
            placeholder="Choose a date"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" />
        </b-col>
        </b-row>
        <b-row>
            <b-col cols="4">
                <label class="selecter-label">Insurance authorization no.</label>
                <b-form-input v-model="insurance_auth_no"></b-form-input>
            </b-col>
            <b-col cols="4">
                <label class="selecter-label">Reference no.</label>
                <b-form-input v-model="reference_no"></b-form-input>
            </b-col>
            <b-col cols="4">
                <label class="selecter-label">No. visits/units</label>
                <b-row>
                    <b-col cols="6">
                            <b-form-input type="number" v-model="quantity_approved"></b-form-input>
                            <div class="avo-text-light ml-2">Approved</div>
                    </b-col>
                    <b-col cols="6 pl-0">
                            <b-form-input type="number" v-model="quantity_remaining"></b-form-input>
                            <div class="avo-text-light ml-2">Remaining</div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
            
        <div class="mt-4" style="text-align:right">
            <b-button @click="submitPreauth" variant="outline-primary" class="avo-primary-btn" :disabled="submitting">
                <b-overlay :show="submitting" variant="white">Submit Preauth Changes</b-overlay>
            </b-button>
        </div>
    </div>
</b-form>
</b-modal>
</div>
</template>

<script>
import { make_url, authenticated_request, makeDateTime, alertsMixin } from '../../common.js'

export default {
  name: 'PreauthEdit',
  mixins: [alertsMixin],
  data: function(){
      return {
          preauth_id: null,

          preauth_state: null,

          quantity_approved: null,
          quantity_remaining: null,
          insurance_auth_no: null,
          reference_no: null,
          effective_start_date: null,
          effective_end_date: null,

          submitting: false,
      }
  },
  methods: {
    submitPreauth(){
        this.submitting = true;
        var data = {
            "state": this.preauth_state,
            "quantity_approved": this.quantity_approved,
            "quantity_remaining": this.quantity_remaining,
            "insurance_auth_no": this.insurance_auth_no,
            "reference_no": this.reference_no,
            "effective_start_date": makeDateTime(this.effective_start_date),
            "effective_end_date": makeDateTime(this.effective_end_date),
        }

        var self = this;
        authenticated_request({
            method: "put",
            url: make_url("/preauths/" + self.selectedPreauth.preauth.preauth_id),
            data: data
        }).then(function(response){
            if (response){
                self.updateSelectedPreauth(self.selectedPreauth, data);
            }
            self.submitting = false;
            self.$bvModal.hide('preauth-edit');
            self.$store.commit('refreshActivityLog');
            self.$emit('submitted');
        }).catch(function(errors){
            self.showError(errors);
            self.submitting = false;
        });
    },
    updateSelectedPreauth(old_data, new_data){
        // Update the selected preauth
        var newPreauth = JSON.parse(JSON.stringify(old_data));
        for (var key of Object.keys(new_data)) {
            newPreauth.preauth[key] = new_data[key]
        }
        console.log("Updating selected preauth with new data: ", newPreauth);
        this.$store.commit("preauths/setSelectedPreauth", newPreauth);
    },
    datetimeToDate(datetime){
        if (datetime != "-"){
            datetime = datetime.split("T")[0];
        }
        return datetime;
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
  },
  computed: {
    selectedPreauth() {
        return this.$store.state.preauths.preauth.selectedPreauth
    },
    preauthStateMap() {
      return this.$store.state.preauths.preauth.preauthStateMap;
    },
  },
  async mounted(){
    this.preauth_id = this.selectedPreauth.preauth.preauth_id,
    this.preauth_state = this.selectedPreauth.preauth.state;
    this.quantity_approved = this.selectedPreauth.preauth.quantity_approved;
    this.quantity_remaining = this.selectedPreauth.preauth.quantity_remaining;
    this.insurance_auth_no = this.selectedPreauth.preauth.insurance_auth_no;
    this.reference_no = this.selectedPreauth.preauth.reference_no;
    this.effective_start_date = this.selectedPreauth.preauth.effective_start_date;
    this.effective_end_date = this.selectedPreauth.preauth.effective_end_date;
  }
}
</script>

<style scoped>

</style>