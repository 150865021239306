<template id="preauthSchedule">
  <div>
    <b-container class="schedule-container mb-5">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <div
          class="d-flex align-items-center justify-content-between"
          style="width: 550px"
        >
          <b-dropdown id="dropdown-1" :text="dropdownTitle">
            <b-dropdown-item @click="toggleCalendarWeek">Week</b-dropdown-item>
            <b-dropdown-item @click="toggleCalendarMonth"
              >Month</b-dropdown-item
            >
            <b-dropdown-item @click="toggleCalendarSchedule"
              >Schedule</b-dropdown-item
            >
          </b-dropdown>
          <div class="d-flex align-items-center">
            <div class="date-header-text mr-2">{{ getTitle }}</div>
            <b-icon
              @click="clickPrev()"
              class="mr-2"
              role="button"
              icon="chevron-left"
              font-scale="1.5"
            ></b-icon>
            <b-icon
              @click="clickNext()"
              role="button"
              icon="chevron-right"
              font-scale="1.5"
            ></b-icon>
          </div>
          <b-button
            @click="clickToday()"
            variant="outline-primary"
            class="avo-basic-btn"
            >Today</b-button
          >
        </div>
        <b-button-group>
          <b-button
            @click="printSchedule()"
            variant="outline-primary"
            class="avo-primary-btn mr-2"
          >
          <b-icon icon="printer" class="mr-2" />Print Schedule
          </b-button>
          <b-button
            :disabled="visitHasPreauth"
            @click="showNewPreauth()"
            variant="outline-primary"
            class="avo-primary-btn"
          >
            <b-icon icon="plus" class="mr-2" />{{
              showManagePreauthQuickView ? "Add Preauth" : "New Visit"
            }}
          </b-button>
        </b-button-group>
      </div>
      <b-alert
        v-model="showNewPreauthSuccess"
        variant="success"
        dismissible
      >
        <b>Successfully added new preauth</b>
      </b-alert>
      <b-row>
        <b-col v-show="!showManagePreauthQuickView">
          <b-row class="schedule shadow-custom">
            <b-col class="calendar-sidebar do-not-show-on-print" cols="2" style="max-width: none">
              <div class="calendar-icon-lrg">
                <b-icon icon="calendar3" :animation="eventsLoading ? 'throb' : ''" scale="1.25" />
              </div>
              <b-calendar
                class="mb-4"
                v-model="datePickerValue"
                @context="onContext"
              />

              <div class="section-header-text mb-2">
                <span style="margin-top: auto;">Filters</span>
                <b-button
                  v-if="filtersAreApplied"
                  @click="clearFilters"
                  variant="outline-primary"
                  class="avo-basic-btn mr-2"
                  >
                  <b-icon icon="x-circle" class="mr-1"/> Clear Filters</b-button
                >
              </div>
              <!-- Patient search TODO: Add patient multiselect -->
              <b-input-group class="search-group mb-4">
                <b-input-group-prepend>
                  <span class="input-group-text"
                    ><b-icon icon="search"></b-icon
                  ></span>
                </b-input-group-prepend>
                <b-form-input
                  v-model="patientSearchText"
                  debounce="500"
                  class="preauth-search-input pl-1"
                  type="search"
                  placeholder="Search patient or account #"
                />
              </b-input-group>

              <label class="selecter-label">Provider</label>
              <div class="d-flex">
                <multiselect
                  placeholder="Filter by provider"
                  :custom-label="providerLabel"
                  v-model="selectedProvider"
                  track-by="provider_id"
                  :options="selectableProviders"
                  :loading="providersLoading"
                  :internal-search="false"
                  :show-labels="false"
                  @search-change="debounceProviders"
                  :showNoOptions="true"
                  :allow-empty="true"
                >
                  <template slot="noResult">
                    No providers matched your search
                  </template>
                  <template slot="noOptions">
                    Start typing a provider name...
                  </template>
                </multiselect>
              </div>

              <label class="selecter-label">Practice</label>
              <multiselect
                placeholder="Filter by practice"
                :custom-label="practiceLabel"
                v-model="selectedPractice"
                track-by="practice_id"
                :options="selectablePractices"
                :loading="practicesLoading"
                :internal-search="false"
                :show-labels="false"
                @search-change="debouncePractices"
                :showNoOptions="true"
                :allow-empty="true"
              >
                <template slot="noResult">
                  No practices matched your search
                </template>
                <template slot="noOptions">
                  Start typing a practice name...
                </template>
              </multiselect>
              
              <label class="selecter-label">Visit Type</label>
              <multiselect
                placeholder="Filter by visit type"
                :custom-label="procedureTypeLabel"
                v-model="selectedProcedureTypes"
                track-by="procedure_type_id"
                :options="selectableProcedureTypes"
                :loading="procedureTypesLoading"
                :internal-search="true"
                :show-labels="false"
                :showNoOptions="true"
                :allow-empty="true"
                :multiple="true"
              >
                <template slot="noOptions">
                  Select a practice to see visit types
                </template>
              </multiselect>

              <label class="selecter-label">Location</label>
              <multiselect
                placeholder="Filter by practice location"
                :custom-label="locationLabel"
                v-model="selectedLocation"
                track-by="location_id"
                :options="selectableLocations"
                :loading="locationsLoading"
                :internal-search="false"
                :show-labels="false"
                @search-change="debounceLocations"
                :showNoOptions="true"
                :allow-empty="true"
              >
                <template slot="noResult">
                  No locations matched your search
                </template>
                <template slot="noOptions">
                  Start typing a location name or address...
                </template>
              </multiselect>

              <label class="selecter-label">Preauth Status</label>
              <multiselect
                v-model="selectedStates"
                :hide-selected="true"
                :options="preauthStateMapArray"
                track-by="state"
                label="name"
                :multiple="true"
                :show-labels="false"
                placeholder="Filter by status"
              >
              </multiselect>

              <label class="selecter-label"
                >Show/hide visits without preauths</label
              >
              <b-form-checkbox-group
                v-model="showAllVisitsOrNoPreauths"
                :options="[
                  { text: 'Preauths', value: 1 },
                  { text: 'Visits Missing Preauths', value: 2 },
                ]"
                buttons
                button-variant="outline-primary"
                class="checkbox-btn-group"
                id="preauths-checkbox-group"
              >
              </b-form-checkbox-group>
            </b-col>
            <b-col class="px-0">
              <full-calendar
                ref="fullCalendar"
                :options="calendarOptions"
              >
                <template v-slot:eventContent="arg">
                  <div
                    class="
                      d-flex
                      flex-column
                      align-items-between
                      justify-content-between
                      p-1
                    "
                  >
                    <!-- Begin shield  -->
                    <div
                      v-if="argEventPreauthStateName(arg) == 'No preauth'"
                      class="mr-1 shield-no-preauth d-flex"
                    >
                      <span><b-icon icon="shield-minus" scale="1.4" />
                      <span class="ml-2">{{ argEventPreauthStateName(arg) }}</span></span>
                    </div>
                    <div v-else-if="argEventPreauthStateName(arg) == 'Approved'" class="mr-1 shield-approved">
                      <span><b-icon icon="shield-check" scale="1.4" />
                      <span><span class="ml-2">{{ argEventPreauthStateName(arg) }}</span></span></span>
                    </div>
                    <div v-else-if="argEventPreauthStateName(arg) == 'No Preauth Required'" class="mr-1 shield-approved">
                      <span><b-icon icon="shield-check" scale="1.4" />
                      <span><span class="ml-2">{{ argEventPreauthStateName(arg) }}</span></span></span>
                    </div>
                    <div v-else-if="argEventPreauthStateName(arg) == 'Partially Approved'" class="mr-1 shield-partially-approved">
                      <span><b-icon icon="shield-exclamation" scale="1.4" />
                      <span><span class="ml-2">{{ argEventPreauthStateName(arg) }}</span></span></span>
                    </div>
                    <div v-else-if="argEventPreauthStateName(arg) == 'Denied'" class="mr-1 shield-denied">
                      <span><b-icon icon="shield-slash" scale="1.4" />
                      <span><span class="ml-2">{{ argEventPreauthStateName(arg) }}</span></span></span>
                    </div>
                    <div v-else class="mr-1 shield-pending">
                      <span><b-icon icon="shield" scale="1.4" />
                      <span class="ml-2">{{ argEventPreauthStateName(arg) }}</span></span>
                    </div>
                    <!-- End shield  -->
                    <!-- Begin time -->
                    <div class="align-items-center">
                      <b>
                        {{ formatTime(arg.event.extendedProps.start_time) }}
                      </b>
                      <b v-if="arg.event.extendedProps.end_time" class="ml-1">
                        - {{ formatTime(arg.event.extendedProps.end_time) }}
                      </b>
                    </div>
                    <!-- End time -->
                    <!-- Begin patient/location name -->
                    <div class="pl-2 justify-content-center">
                      <div style="color: var(--dark); font-size: 11px">
                        {{ arg.event.extendedProps.patient.full_name ? arg.event.extendedProps.patient.full_name : "Unknown Patient" }}
                      </div>
                      <div style="color: var(--gray); font-size: 10px">
                        Acct#
                        {{ arg.event.extendedProps.patient.account_no || "N/A" }}
                      </div>
                      <div style="color: var(--gray); font-size: 10px">
                        {{ arg.event.extendedProps.location_name }}
                      </div>
                    </div>
                    <!-- End patient/location name -->
                    <!-- Begin CPT codes -->
                    <div>
                      <div v-if="arg.event.extendedProps.preauth_cptcodes">
                        <div
                          v-for="(cptCode, index) in arg.event.extendedProps.preauth_cptcodes.slice(0,
                            arg.event.extendedProps.preauth_cptcodes.length > 3
                              ? 2
                              : 3)"
                          :key="index"
                          class="d-flex">
                          <div class="event-cptcode">
                            {{ cptCode.code }}
                          </div>
                          <div class="event-cptdescription ml-1">
                            - {{ cptCode.short }}
                          </div>
                        </div>
                        <div class="event-cptcode" v-if="arg.event.extendedProps.preauth_cptcodes.length > 3">
                          <span>+{{ arg.event.extendedProps.preauth_cptcodes.length - 2 }} more codes</span>
                        </div>
                      </div>
                    </div>
                    <!-- End CPT codes -->
                  </div>
                </template>
              </full-calendar>
            </b-col>
          </b-row>
        </b-col>
        <b-col v-if="showManagePreauthQuickView" class="pr-0">
          <b-row class="preview shadow-custom ml-2">
            <b-col class="preview px-0">
              <preauth-manage @preauthsUpdated="getCalendarEvents()" />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";
import {
  make_url,
  authenticated_request,
  formatDate,
  formatTime,
  debounce,
  permissionsMixin,
} from "../../common.js";
import Multiselect from "vue-multiselect";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
// import VueHtmlToPaper from "vue-html-to-paper";
import PreauthManage from "./PreauthManage.vue";

// const options = {
//   name: "_blank",
//   specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
//   // Need to somehow get bootstrap.css and bootstrap-vue.css here, otherwise styles
//   // won't show up
//   styles: ["./../../assets/main.css", "./../../assets/schedule-printing.css"],
//   timeout: 1000, // default timeout before the print window appears
//   autoClose: false, // if false, the window will not close after printing
//   windowTitle: "Printed Schedule", // override the window title
// };

// Vue.use(VueHtmlToPaper, options);

export default {
  name: "PreauthSchedule",
  mixins: [permissionsMixin],
  template: "#preauthSchedule",
  components: {
    Multiselect,
    FullCalendar,
    PreauthManage,
  },
  data() {
    return {
      title: "",
      dropdownTitle: "Schedule",
      calendarView: "listWeek",
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        height: "100%",
        expandRows: false,
        hiddenDays: [],
        dayMaxEvents: 2,
        eventMaxStack: 3,
        moreLinkClick: "listWeek",
        nowIndicator: true,
        initialView: "listWeek",
        headerToolbar: false,
        stickyHeaderDates: true,
        dateClick: this.handleDateClick,
        eventClick: this.toggleShowManagePreauthQuickView,
        events: [],
      },
      datePickerValue: "",
      datePickerContext: null,
      showStatus: false,
      showProcedureType: false,

      currentDateRange: {
        startDate: null,
        endDate: null,
      },
      
      eventsLoading: true,

      // for superadmin provider/practice multiselects
      providersLoading: false,
      selectableProviders: [],
      selectedProvider: null,
      providerSearchText: "",

      // location multiselect stuff
      locationSearchText: "",
      selectedLocation: null,
      locationsLoading: false,
      selectableLocations: [],
      
      // procedure type multiselect stuff
      procedureTypesLoading: false,
      selectedProcedureTypes: null,
      selectableProcedureTypes: [],

      // location multiselect stuff
      practiceSearchText: "",
      selectedPractice: null,
      practicesLoading: false,
      selectablePractices: [],

      // patient search stuff
      patientSearchText: "",

      selectedStates: [],

      showAllVisitsOrNoPreauths: [1],

      output: null,
    };
  },
  methods: {
    printSchedule() {
      this.toggleCalendarSchedule();
      window.print();
    },
    setEventsLoading(value) {
      this.eventsLoading = value;
    },
    clearFilters() {
      this.selectedProvider = null;
      this.selectedLocation = null;
      this.selectedPractice = null;
      this.selectedProcedureTypes = null;
      this.selectedStates = [];
      this.patientSearchText = "";
      this.setInitial();
    },
    getCalendarEvents() {
      var params = {
        "visit_date:start_date": this.currentDateRange.startDate,
        "visit_date:end_date": this.currentDateRange.endDate,
        patient_search: this.patientSearchText,
      };

      if (this.selectedLocation) {
        params.location_ids = this.selectedLocation.location_id;
      }
      if (this.selectedProvider) {
        params.provider_ids = this.selectedProvider.provider_id;
      }
      if (this.selectedPractice) {
        params.practice_ids = this.selectedPractice.practice_id;
      }
      if (this.selectedProcedureTypes) {
        params.procedure_types = this.selectedProcedureTypes
                                     .map((item) => item.procedure_type_id)
                                     .join(",");
      }
      if (this.selectedStates) {
        params.preauth_states = this.selectedStates
                                    .map((item) => item.state)
                                    .join(",");
      }

      // 0: hide all visits, 1: show visits with preauths only, 2: show visits missing preauths only, 3: show both
      const showAllVisitsOrNoPreauthsOption =
        this.showAllVisitsOrNoPreauths.reduce((a, b) => {
          return a + b;
        }, 0);
      if (showAllVisitsOrNoPreauthsOption == 0) {
        this.calendarOptions.events = [];
        return Promise.resolve();
      }
      params.show_visits_missing_preauths = showAllVisitsOrNoPreauthsOption;

      const url = make_url("/reports/preauth");
      const vm = this;
      vm.eventsLoading = true;
      const promise = authenticated_request({
        method: "get",
        url: url,
        params: params,
      })
        .then(function (response) {
          if (response) {
            vm.calendarOptions.events = response.data.visits;
            vm.eventsLoading = false;
          }
        })
        .catch(function (error) {
          console.log(error.response);
        })
        .finally(() => {});
      return promise;
    },
    argEventPreauthStateName(arg) {
      const stateValue = arg?.event?.extendedProps?.preauth?.state
      if (stateValue === null) {
        return "No preauth";
      }
      else if (!this.preauthStateMap[stateValue]) {
        return "Unknown";
      }
      return this.preauthStateMap[stateValue]
    },
    showNewPreauth() {
      this.$store.commit("preauths/setShowNewPreauth", true);
      this.$store.commit("preauths/setShowNewPreauthSuccess", false);
    },
    toggleShowManagePreauthQuickView(args) {
      if (!this.showManagePreauthQuickView) {
        const date = new Date(args.event._instance.range.start);
        const dayIndex = date.getDay();
        var dayArr = [0, 1, 2, 3, 4, 5, 6];
        dayArr.splice(dayIndex, 1);
        this.calendarOptions.hiddenDays = dayArr;

        const selectedEvent = args.event._def.extendedProps;
        this.$store.commit("preauths/setSelectedPreauth", selectedEvent);
      } else {
        this.calendarOptions.hiddenDays = [];
        this.$store.commit("preauths/clearSelectedPreauth");
      }
      this.$store.commit("preauths/toggleShowManagePreauthQuickView");
    },
    toggleCalendarWeek() {
      this.dropdownTitle = "Week";
      this.calendarView = "timeGridWeek";
      this.setCalendar(this.calendarView);
    },
    toggleCalendarMonth() {
      this.dropdownTitle = "Month";
      this.calendarView = "dayGridMonth";
      this.setCalendar(this.calendarView);
    },
    toggleCalendarSchedule() {
      this.dropdownTitle = "Schedule";
      this.calendarView = "listWeek";
      this.setCalendar(this.calendarView);
    },
    setInitial() {
      this.title = this.$refs.fullCalendar.getApi().view.title;
      this.currentDateRange.startDate =
        this.$refs.fullCalendar.getApi().view.activeStart;
      this.currentDateRange.endDate =
        this.$refs.fullCalendar.getApi().view.activeEnd;
      this.getCalendarEvents();
      this.scrollToEarliestVisit();
    },
    setCalendar() {
      this.$refs.fullCalendar.getApi().changeView(this.calendarView);
      this.setInitial();
    },
    clickToday() {
      this.$refs.fullCalendar.getApi().today();
      this.setInitial();
    },
    clickPrev() {
      this.$refs.fullCalendar.getApi().prev();
      this.setInitial();
    },
    clickNext() {
      this.$refs.fullCalendar.getApi().next();
      this.setInitial();
    },
    scrollToEarliestVisit() {
      if (this.calendarOptions.events.length > 0) {
        // get earliest event start time
        const earliestTime = this.calendarOptions.events[0].start_time;
        const dateObj = new Date(earliestTime);

        // subtract two hours so scrollto doesnt make first event flush with top of calendar
        dateObj.setHours(dateObj.getHours() - 1);

        // get the local time string in 24hr format, and scroll calendar to it.
        const splits = dateObj.toLocaleTimeString("en-GB").split(" ");
        this.$refs.fullCalendar.getApi().scrollToTime(splits[0]);
      }
    },
    onContext(context) {
      this.datePickerContext = context;
    },
    formatDate,
    formatTime,
    providerSearchChange: function (searchQuery) {
      this.providersLoading = true;
      this.providerSearchText = searchQuery;
      if (searchQuery.length > 0) {
        this.providerAutocomplete();
      } else {
        this.providersLoading = false;
      }
    },
    getProcedureTypesOptions: function () {
      const vm = this;
      const practice_id = this.selectedPractice?.practice_id;
      if (!practice_id) {
        return
      }
      const url = make_url("/lookup/visits/procedure_types/practice/" + practice_id).toString();
      authenticated_request({
        method: "get",
        url: url,
      }).then(function (response) {
        vm.procedureTypesLoading = false;
        const procedureTypes = response.data.procedure_types;
        vm.selectedProcedureTypes = null;
        vm.selectableProcedureTypes = procedureTypes || [];
        return procedureTypes || [];
      }).catch(function (errors) {
        vm.showError(errors);
      });
    },
    providerAutocomplete() {
      var vm = this;
      const url = make_url("/reports/providers/autocomplete");
      authenticated_request({
        method: "get",
        url: url,
        params: {
          search: this.providerSearchText,
          limit: 20,
        },
      })
        .then(function (response) {
          vm.providersLoading = false;

          const providers = response.data.providers;
          vm.selectableProviders = providers || [];
          return providers || [];
        })
        .catch(function (errors) {
          vm.showError(errors);
        });
    },
    providerLabel(provider) {
      return provider.full_name_with_suffix;
    },
    locationSearchChange: function (searchQuery) {
      this.locationsLoading = true;
      this.locationSearchText = searchQuery;
      if (searchQuery.length > 0) {
        this.locationAutocomplete();
      } else {
        this.locationsLoading = false;
      }
    },
    practiceAutocomplete() {
      var vm = this;
      const url = make_url("/reports/practices/autocomplete");
      authenticated_request({
        method: "get",
        url: url,
        params: {
          search: this.practiceSearchText,
          limit: 10,
        },
      })
        .then(function (response) {
          vm.locationsLoading = false;
          const practices = response.data.practices;
          vm.selectablePractices = practices || [];
          return practices || [];
        })
        .catch(function (errors) {
          vm.showError(errors);
        });
    },

    practiceSearchChange: function (searchQuery) {
      this.practicesLoading = true;
      this.practiceSearchText = searchQuery;
      if (searchQuery.length > 0) {
        this.practiceAutocomplete();
      } else {
        this.practicesLoading = false;
      }
    },

    practiceLabel(practice) {
      if (practice.name) {
        return practice.name;
      } else {
        return practice.practice_id;
      }
    },

    procedureTypeLabel(procedure) {
      if (procedure.name) {
        return procedure.name;
      } else {
        return procedure.procedure_type_id;
      }
    },

    locationAutocomplete() {
      var vm = this;
      const url = make_url(
        "/reports/practices/locations/autocomplete"
      ).toString();
      authenticated_request({
        method: "get",
        url: url,
        params: {
          search: this.locationSearchText,
          practice_id: this.hasPermission("see_other_provider_metrics")
            ? null
            : this.user_practice_id,
          limit: 10,
        },
      })
        .then(function (response) {
          vm.locationsLoading = false;
          const locations = response.data.locations;
          vm.selectableLocations = locations || [];
          return locations || [];
        })
        .catch(function (errors) {
          vm.showError(errors);
        });
    },
    locationLabel(location) {
      if (location.name) {
        return location.name;
      } else {
        return location.location_id;
      }
    },
  },
  computed: {
    user_provider: function () {
      return JSON.parse(localStorage.getItem("provider")) || null;
    },
    user_practice_id: function () {
      return localStorage.getItem("practice_id");
    },
    showManagePreauthQuickView: function () {
      return this.$store.state.preauths.preauth.showManagePreauthQuickView;
    },
    getTitle() {
      return this.title;
    },
    visitHasPreauth() {
      return !!this.$store.state.preauths.preauth?.selectedPreauth?.preauth
        ?.preauth_id;
    },
    showNewPreauthSuccess: {
      get() {
        return this.$store.state.preauths.preauth.showNewPreauthSuccess;
      },
      set(newValue) {
        return this.$store.commit(
          "preauths/setShowNewPreauthSuccess",
          newValue
        );
      },
    },
    preauthsUpdated() {
      return this.$store.state.preauths.preauth.preauthsUpdated;
    },
    filtersAreApplied() {
      if (this.selectedProvider 
          || this.selectedLocation 
          || this.selectedPractice 
          || this.selectedStates.length > 0 
          || this.patientSearchText
      ) {
        return true;
      }
      return false;
    },
    preauthStateMap() {
      return this.$store.state.preauths.preauth.preauthStateMap;
    },
    preauthStateMapArray() {
      return this.$store.state.preauths.preauth.preauthStateMapArray;
    },
  },
  watch: {
    preauthsUpdated() {
      this.$store.commit("preauths/setPreauthsUpdated", false);
      this.setInitial();
    },
    patientSearchText() {
      this.setInitial();
    },
    selectedProvider() {
      this.setInitial();
    },
    selectedLocation() {
      this.setInitial();
    },
    selectedPractice() {
      this.setInitial();
      this.getProcedureTypesOptions();
    },
    selectedProcedureTypes() {
      this.setInitial();
    },
    selectedStates() {
      this.setInitial();
    },
    showAllVisitsOrNoPreauths() {
      this.setInitial();
    },
    showManagePreauthQuickView: function (newValue) {
      if (!newValue) {
        this.calendarOptions.hiddenDays = [];
      }
      this.$nextTick(function () {
        this.$refs.fullCalendar.getApi().updateSize();
      });
    },
    datePickerValue: function () {
      this.$refs.fullCalendar.getApi().gotoDate(this.datePickerValue);
      this.$refs.fullCalendar.getApi().select(this.datePickerValue);
      this.setInitial();
    },
  },
  mounted() {
    if (!this.user_provider) {
      this.setInitial();
    }
    if (this.user_provider) {
      this.selectedProvider = this.user_provider;
    } else if (this.user_practice_id) {
      this.selectedPractice = this.user_practice_id;
    }
  },
  created() {
    this.debouncePractices = debounce(this.practiceAutocomplete, 300);
    this.debounceProviders = debounce(this.providerSearchChange, 300);
    this.debounceLocations = debounce(this.locationSearchChange, 300);
  },
};
</script>

<style scoped>
::v-deep .multiselect__spinner {
  background: #dde4f5 !important;
}
.date-header-text {
  font-weight: 500;
  font-size: 22px;
  color: var(--dark);
}
.section-header-text {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  color: var(--dark);
}
.avo-basic-btn,
.avo-basic-btn:focus {
  min-width: 80px !important;
}
.schedule-container {
  max-width: 3000px;
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
}
.schedule {
  background-color: white;
  border-radius: 15px;
  min-height: 82vh;
  max-height: 120vh;
  box-shadow: 1px 1px 10px #16171814;
}
.preview {
  background-color: white;
  border-radius: 15px;
  box-shadow: 1px 1px 10px #16171814;
}
.calendar-sidebar {
  border-right: 2px solid #c9d2df;
  padding-top: 16px;
  padding-bottom: 16px;
}
.calendar-icon-lrg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: var(--dark);
  background-color: white;
  box-shadow: 1px 1px 5px #16171829;
  border-radius: 20px;
  margin-bottom: 16px;
}
.shield-text {
  font-size: 9px;
  font-weight: 500;
  position: absolute;
  top: 1px;
  left: 25%;
}
.shield-no-preauth {
  color: grey;
}
.shield-approved {
  color: var(--pink);
}
.shield-partially-approved {
  color: #ff7f07;
  font-size: 14px;
}
.shield-pending {
  color: #ffd000;
}
.shield-denied {
  color: #f05c50;
}

/* overriding calendar styles */
::v-deep .b-calendar-nav {
  padding-left: 50%;
  margin-bottom: -30px;
  justify-content: flex-end !important;
}
::v-deep .b-calendar-nav .btn-outline-secondary {
  color: var(--dark);
  border-color: none;
  padding: 3px 0;
  border-radius: 12px;
}
::v-deep .b-calendar-nav .btn-outline-secondary:hover {
  color: var(--dark);
  background-color: transparent;
}
::v-deep .b-calendar-nav .btn-outline-secondary [title~="Current month"] {
  display: none;
  color: red;
}
::v-deep .fc-today {
  background: #fff !important;
  border: none !important;
  border-top: 1px solid #ddd !important;
  font-weight: bold;
}
::v-deep .fc-scrollgrid-sync-inner a {
  display: inline-block;
  padding: 2px 4px;
  color: #010c14;
  font-weight: 400;
  font-size: 14px;
}
::v-deep .fc .fc-scrollgrid table {
  border-bottom-style: hidden;
}
::v-deep .fc-theme-standard .fc-scrollgrid {
  border: none !important;
  border-top-right-radius: 15px;
}
::v-deep .fc .fc-timegrid-slot-minor {
  border-top: none;
}
::v-deep .fc .fc-timegrid-slot-label {
  /* padding:0 !important; */
  width: 50px;
  padding-left: 20px;
  position: relative;
  vertical-align: top;
  border: none;
  font-size: 12px;
  letter-spacing: 0px;
  color: #64728c;
  text-transform: uppercase;
}
::v-deep .fc-theme-standard td:not(:last-child),
.fc-theme-standard th:not(:last-child) {
  border-left: 1px solid #e0eaf8;
  border-right: 1px solid #e0eaf8;
}

::v-deep
  .fc-timegrid-slots
  tbody:not(:first-child)
  .fc-timegrid-slot-label-cushion {
  position: absolute;
  top: -8px !important;
  right: 5px;
}
::v-deep .fc .fc-timegrid .fc-daygrid-body {
  display: none;
}

/* calendar event styles */
::v-deep .fc-v-event {
  box-shadow: 1px 1px 10px #16171814 !important;
  min-height: 150px;
  min-width: 150px;
  padding: 5px;
  font-size: 11px;
  background-color: #f2f6fc;
  border-radius: 10px;
  border: 1px solid #c9d2df;
}
::v-deep .fc-v-event .fc-event-main {
  color: var(--dark);
}

/* Styling the search bar group */
::v-deep .search-group {
  border: none;
  border-radius: 5px;
  /* max-width:200px; */
  height: 50px;
}
::v-deep .preauth-search-input,
.input-group-text {
  height: 50px;
  border: none;
  border-radius: 5px;
  background: #f2f6fc 0% 0% no-repeat padding-box;
  color: #9ca3ad;
}
::v-deep .preauth-search-input::placeholder {
  color: #9ca3ad;
  font-size: 12px;
  opacity: 1;
}

/* small calendar styling */
::v-deep .fc-event {
  height: min-content;
  white-space: normal;
  overflow: hidden;
}
::v-deep .event-cptcode {
  color: var(--dark);
  font-size: 12px;
  font-weight: 500;
}
::v-deep .event-cptdescription {
  color: var(--gray);
  font-size: 10px;
}
::v-deep .b-calendar-header {
  display: none !important;
}
::v-deep .b-calendar .b-calendar-grid {
  border: none !important;
}
::v-deep .b-calendar .b-calendar-grid .row {
  border-bottom: none !important;
}
::v-deep .b-calendar .b-calendar-grid-body .col[data-date] .btn {
  font-size: 12px !important;
  margin: 1px auto;
}
::v-deep .b-calendar-grid-weekdays {
  padding: 0 1px;
  margin-bottom: 8px;
}
::v-deep .b-calendar .text-muted {
  color: #9ca3ad !important;
}
::v-deep .b-calendar-grid-help {
  display: none;
}
::v-deep .b-calendar-grid-caption {
  font-weight: 500;
  font-size: 16px;
  color: #010c14;
  text-align: left !important;
  margin-bottom: 24px;
}
::v-deep small {
  display: block;
  color: transparent;
}
::v-deep small::first-letter {
  margin-left: 12px;
  font-size: 12px !important;
  color: #010c14 !important;
}

::v-deep .btn-primary:not(:disabled):not(.disabled).active {
  color: #eef1fa;
  background-color: var(--blue);
  box-shadow: 1px 1px 3px #16171826;
}
/* dropdown styling (for week/month/schedule dropdown) */
.dropdown {
  border-radius: 5px;
}
.dropdown ::v-deep .btn-secondary {
  background-color: white;
  border: 1px solid #c9d2df;
  min-height: 40px;
  /* max-width: 150px !important; */
  color: var(--dark);
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  padding-right: 30px;
  white-space: normal;
  overflow: hidden;
}
::v-deep .b-dropdown .btn-secondary:active,
::v-deep .btn-secondary:not(:disabled):not(.disabled):active,
::v-deep .btn-secondary:not(:disabled):not(.disabled).active,
::v-deep .show > .btn-secondary.dropdown-toggle {
  color: black;
  border: 1px solid #c9d2df;
  /* background-color: #F7F8FA; */
  background-color: #dde4f5;
}
::v-deep .b-dropdown .btn-secondary:focus {
  box-shadow: none !important;
}
::v-deep .b-dropdown .btn-secondary:hover {
  background-color: #f2f6fc;
}
::v-deep .avo-dropdown .dropdown-menu {
  left: auto !important;
  right: 0 !important;
  font-size: 14px;
  font-weight: 500;
  color: #64728c;
}
::v-deep .multiselect__select {
  top: 0;
}
::v-deep .dropdown-toggle::after {
  color: var(--gray);
  position: absolute;
  right: 10px;
  border: none !important;
  font-family: "Font Awesome 5 Free" !important;
  content: "\f078" !important;
  font-weight: 900 !important;
}
::v-deep .status-dropdown .dropdown-toggle::after {
  top: 10px;
}

/* Styling for claim checkbox button group */
.checkbox-btn-group {
  min-height: 40px;
}
::v-deep .checkbox-btn-group .btn-outline-primary {
  border: 1px solid #c9d2df;
  padding-top: 9px;
  font-size: 14px;
  color: var(--gray);
}
::v-deep .checkbox-btn-group .btn-outline-primary:hover {
  background-color: #f2f6fc;
  color: #595d63;
}
::v-deep .checkbox-btn-group .btn-outline-primary:active,
::v-deep .checkbox-btn-group .btn-outline-primary.active {
  border: 1px solid var(--blue) !important;
  background-color: #f2f6fc !important;
  color: var(--dark);
}
::v-deep .checkbox-btn-group .btn-outline-primary:focus,
::v-deep .checkbox-btn-group .btn-outline-primary.focus {
  box-shadow: none !important;
}
</style>