















































































































































































































































































































































































































































































import Vue from "vue";
import {
  make_url,
  authenticated_request,
  formatFullDate,
  formatDateShort,
  formatTime,
} from "../../common.js";
import PreauthEdit from "./PreauthEdit.vue";
import ClaimVisit from "../visits/Visit.vue";
import InsuranceList from "../payers/InsuranceList.vue";
import CptCodesList from "../codes/CptCodesList.vue";
import IcdCodesList from "../codes/IcdCodesList.vue";
import NotesList from "../notes/NotesList.vue";
import DocumentList from "../documents/DocumentList.vue";
import ActivityManager from "../activity/ActivityManager.vue";
import PayerPreauthDetails from "../payers/PayerPreauthDetails.vue";

export default Vue.extend({
  name: "PreauthManage",
  template: "#preauthManage",
  components: {
    NotesList,
    ActivityManager,
    PreauthEdit,
    DocumentList,
    InsuranceList,
    ClaimVisit,
    CptCodesList,
    IcdCodesList,
    PayerPreauthDetails,
  },
  data() {
    return {
      currentPreviewSection: "appointment",
      preauthEditLoading: false,
      insurancesLoading: true,
    };
  },
  methods: {
    exitPreviewView() {
      this.$store.commit("preauths/clearSelectedPreauth");
      this.$store.commit("preauths/clearInsurances");
      this.$store.commit("preauths/clearVisit");
      this.$store.commit("preauths/setShowManagePreauthQuickView", false);
    },
    setInsurances(insurances: any) {
      this.$store.commit("preauths/setInsurances", insurances);
      this.insurancesLoading = false;
    },
    setVisit(visit: any) {
      this.$store.commit("preauths/setVisit", visit);
      this.$emit("preauthsUpdated");
    },
    emitPreauthUpdated() {
      this.$emit("preauthsUpdated");
    },
    goPreauthDashboard: function () {
      this.exitPreviewView();
      this.$store.commit("preauths/setShowManagePreauth", false);
    },
    goPreauthManage: function () {
      this.$store.commit("preauths/setShowManagePreauth", true);
    },
    goToSection: function (refName: string) {
      var element = this.$refs[refName];
      this.currentPreviewSection = refName;
      if (element instanceof Element) {
        element.scrollIntoView();
      }
    },
    formatFullDate(datetime: any) {
      return formatFullDate(datetime);
    },
    formatDateShort(datetime: any) {
      return formatDateShort(datetime);
    },
    formatTime(datetime: any) {
      return formatTime(datetime);
    },
    formatInsurancePrimacy(insurance_primacy: string | null | undefined) {
      if (insurance_primacy === null || insurance_primacy === undefined) {
        return "U";
      } else {
        return insurance_primacy.charAt(0).toUpperCase();
      }
    },
  },
  computed: {
    isQuickView(): Boolean {
      return !this.$store.state.preauths.preauth.showManagePreauth;
    },
    visitHasPreauth(): Boolean {
      // TODO: Wow this is a lot of .preauth's
      return !!this.$store.state.preauths.preauth.selectedPreauth.preauth
        .preauth_id;
    },
    selectedPreauth(): any {
      return this.$store.state.preauths.preauth.selectedPreauth;
    },
    visit(): any {
      if (this.$store.state.preauths.visit.visit) {
        return this.$store.state.preauths.visit.visit;
      } else {
        return {
          start_time: this.selectedPreauth.start_time,
          end_time: this.selectedPreauth.end_time,
          date_of_service: this.selectedPreauth.date_of_service,
        };
      }
    },
    insurances(): any {
      return this.$store.state.preauths.insurance.insurances;
    },
    primaryInsurance(): any {
      return this.$store.getters["preauths/insuranceModulePrimaryInsurance"];
    },
    payer_id(): number | null {
      return this.primaryInsurance?.payer_id;
    },
    practice_id(): number | null {
      return this.$store.state.preauths.preauth.selectedPreauth;
    },
    preauthStateMap(): any {
      return this.$store.state.preauths.preauth.preauthStateMap;
    },
  },
});
